import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import commonTranslationES from './locales/es/common.json';
import commonTranslationEN from './locales/en/common.json';
import navbarTranslationES from './locales/es/navbar.json';
import navbarTranslationEN from './locales/en/navbar.json';
import homeTranslationES from './locales/es/home.json';
import homeTranslationEN from './locales/en/home.json';
import footerTranslationES from './locales/es/footer.json';
import footerTranslationEN from './locales/en/footer.json';
import quienesSomosTranslationES from './locales/es/quienesSomos.json';
import quienesSomosTranslationEN from './locales/en/quienesSomos.json';
import bibliotecaDigitalES from './locales/es/bibliotecaDigital.json';
import bibliotecaDigitalEN from './locales/en/bibliotecaDigital.json';
import comoTransformamosES from './locales/es/comoTransformamos.json';
import comoTransformamosEN from './locales/en/comoTransformamos.json';
import apoyaCeeadES from './locales/es/apoyaCeead.json';
import apoyaCeeadEN from './locales/en/apoyaCeead.json';
import blogCeeadES from './locales/es/blogCeead.json';
import blogCeeadEN from './locales/en/blogCeead.json';
import equipoCeeadES from './locales/es/equipoCeead.json';
import preguntasFrecuentesES from './locales/es/preguntasFrecuentes.json';
import preguntasFrecuentesEN from './locales/en/preguntasFrecuentes.json';
import proximosEventosES from './locales/es/proximosEventos.json';
import proximosEventosEN from './locales/en/proximosEventos.json';
import redesColaboracionES from './locales/es/redesColaboracion.json';
import redesColaboracionEN from './locales/en/redesColaboracion.json';
import investigacionES from './locales/es/investigacion.json';
import investigacionEN from './locales/en/investigacion.json';
import evaluacionCertificacionES from './locales/es/evaluacionCertificacion.json';
import evaluacionCertificacionEN from './locales/en/evaluacionCertificacion.json';
import eventosInstitucionalesES from './locales/es/eventosInstitucionales.json';
import eventosInstitucionalesEN from './locales/en/eventosInstitucionales.json';
import perfilesHistoricosES from './locales/es/perfilesHistoricos.json';
import perfilesHistoricosEN from './locales/en/perfilesHistoricos.json';
import avisoPrivacidadES from './locales/es/avisoPrivacidad.json';
import avisoPrivacidadEN from './locales/en/avisoPrivacidad.json';
import noticiasCeeadES from './locales/es/noticiasCeead.json';
import noticiasCeeadEN from './locales/en/noticiasCeead.json';
import contactoES from './locales/es/contacto.json';
import contactoEN from './locales/en/contacto.json';
import bolsaTrabajoES from './locales/es/bolsaTrabajo.json';
import bolsaTrabajoEN from './locales/en/bolsaTrabajo.json';
import terminosCondicionesES from './locales/es/terminosCondiciones.json';
import terminosCondicionesEN from './locales/en/terminosCondiciones.json';
import legalesES from './locales/es/legales.json';
import legalesEN from './locales/en/legales.json';
import verificarBoletinES from './locales/es/verificarBoletin.json';
import verificarBoletinEN from './locales/en/verificarBoletin.json';

import { I18N_KEY } from '../settings/settings';

const resources = {
  es: {
    common: commonTranslationES,
    navbar: navbarTranslationES,
    home: homeTranslationES,
    footer: footerTranslationES,
    quienesSomos: quienesSomosTranslationES,
    bibliotecaDigital: bibliotecaDigitalES,
    comoTransformamos: comoTransformamosES,
    apoyaCeead: apoyaCeeadES,
    blogCeead: blogCeeadES,
    equipoCeead: equipoCeeadES,
    preguntasFrecuentes: preguntasFrecuentesES,
    proximosEventos: proximosEventosES,
    redesColaboracion: redesColaboracionES,
    investigacion: investigacionES,
    evaluacionCertificacion: evaluacionCertificacionES,
    eventosInstitucionales: eventosInstitucionalesES,
    perfilesHistoricos: perfilesHistoricosES,
    avisoPrivacidad: avisoPrivacidadES,
    noticiasCeead: noticiasCeeadES,
    contacto: contactoES,
    bolsaTrabajo: bolsaTrabajoES,
    terminosCondiciones: terminosCondicionesES,
    legales: legalesES,
    verificarBoletin: verificarBoletinES,
  },
  en: {
    common: commonTranslationEN,
    navbar: navbarTranslationEN,
    home: homeTranslationEN,
    footer: footerTranslationEN,
    quienesSomos: quienesSomosTranslationEN,
    bibliotecaDigital: bibliotecaDigitalEN,
    comoTransformamos: comoTransformamosEN,
    apoyaCeead: apoyaCeeadEN,
    blogCeead: blogCeeadEN,
    preguntasFrecuentes: preguntasFrecuentesEN,
    proximosEventos: proximosEventosEN,
    redesColaboracion: redesColaboracionEN,
    investigacion: investigacionEN,
    evaluacionCertificacion: evaluacionCertificacionEN,
    eventosInstitucionales: eventosInstitucionalesEN,
    perfilesHistoricos: perfilesHistoricosEN,
    avisoPrivacidad: avisoPrivacidadEN,
    noticiasCeead: noticiasCeeadEN,
    contacto: contactoEN,
    bolsaTrabajo: bolsaTrabajoEN,
    terminosCondiciones: terminosCondicionesEN,
    legales: legalesEN,
    verificarBoletin: verificarBoletinEN,
  },
};

let storedLanguage = window.localStorage.getItem(I18N_KEY);
if (!['es', 'en'].includes(storedLanguage)) {
  storedLanguage = 'es';
}

export default i18n
  .use(initReactI18next)
  .on('languageChanged', (lng) => {
    window.localStorage.setItem(I18N_KEY, lng);
  })
  .init({
    resources,
    lng: storedLanguage,
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false,
    },
  });
