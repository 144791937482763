import parse from 'html-react-parser';
import { motion } from 'framer-motion';
import './modal.css';
import * as Analytics from '../../../../../helpers/analytics';

export const ModalPerfil = ({ closeModal, modalContent }) => {
  Analytics.modalView(`Equipo CEEAD | ${modalContent.person}`);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.4 } }}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}
      className="fondo"
      onClick={closeModal}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1, transition: { duration: 0.4 } }}
        exit={{ opacity: 0, transition: { duration: 0.3 } }}
        className="contenido-perfil"
      >
        {parse(modalContent.content)}
      </motion.div>
    </motion.div>
  );
};
