import { useState, useEffect } from 'react';

const useMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 767 && window.innerWidth < 1024);

  const onResize = () => {
    window.innerWidth < 1024 ? setIsMobile(true) : setIsMobile(false);
    window.innerWidth > 767 && window.innerWidth < 1024 ? setIsTablet(true) : setIsTablet(false);
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return { isMobile, isTablet };
};

export default useMobile;
