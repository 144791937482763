import { useEffect } from 'react';
import quienesSomos from './images/quienes_somos.png';
import slide1es from './images/slides/slide1_23es.png';
import slide2es from './images/slides/slide2_23es.png';
import slideNovedades from './images/slides/novedades_editoriales.png';
import herramientas from './images/ct_herramientas.jpg';
import investigaon from './images/ct_investigacion.jpg';
import capactiacion from './images/ct_capacitacion.jpg';
import eventos from './images/ct_eventos.jpg';
import redes from './images/ct_redes_colaboracion.jpg';
import evaluacion from './images/ct_evaluacion_certificacion.jpg';
import herramientasEn from './images/ct_herramientas_en.jpg';
import investigaonEn from './images/ct_investigacion_en.jpg';
import capactiacionEn from './images/ct_capacitacion_en.jpg';
import eventosEn from './images/ct_eventos_en.jpg';
import redesEn from './images/ct_redes_colaboracion_en.jpg';
import evaluacionEn from './images/ct_evaluacion_certificacion_en.jpg';
import biblioteca from './images/Biblioteca_Digital_Landing.png';
import Informe2023 from './images/slides/Informe2023.jpg';
import Cuadernos from './images/slides/Cuadernos.jpg';
import Jornadas from './images/slides/Jornadas.jpg';
import congreso from './images/slides/BannerWeb_CC2025.jpg';
import premiosEngrane from './images/slides/BannerWeb_Engrane2025.jpg';
import semilla from './images/slides/BanerWebResultadosSemilla.jpg';
import especialidad from './images/slides/BannerWeb_Especialidad.jpg';

import { Link } from 'react-router-dom';
import { SectionImage } from '../../ui/sections/SectionImage';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../hooks/useTitle';
import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics';
import Slider from '../../ui/sections/Slider';

import pdfDiplomado from '../../../assets/pdf/Brochure__Diplomado_de_ética_y_responsabilidad.pdf';
import resultadosSemilla from '../../../assets/pdf/Resultados_2024-2025.pdf';
import Noticias from '../../../data/noticias.json';

const NoticiaCard = ({ title, date, desc, img }) => {
  return (
    <>
      <div className="divider divider-rounded divider-center">
        <i className="icon-news" />
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-3">
            <span className="font-weight-bold" style={{ fontSize: '1.125rem' }}>
              {title}
            </span>
            <br />
            <span>
              <i className="icon-calendar-alt1" /> {date}
            </span>
          </div>
          <p>{desc}</p>
          <Link to="/noticias">
            Ver mas <i className="icon-arrow-alt-circle-right1" />
          </Link>
        </div>
        <div className="col-lg-6">
          <img
            src={`https://media.ceead.org.mx${img}`}
            style={{ width: '100%' }}
            className="shadow-lg rounded"
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export const Home = ({ redirect }) => {
  const redirecttoPdf = (pdf) => {
    const link = document.createElement('a');
    link.target = '_self';
    link.rel = 'noopener noreferrer';
    link.href = pdf;
    setTimeout(() => link.click(), 1000);
  };

  useEffect(() => {
    if (redirect === 'pdfDiplomado') {
      redirecttoPdf(pdfDiplomado);
    }
    if (redirect === 'guia_pedagogica_CEC') {
      redirecttoPdf(
        'https://media.ceead.org.mx/biblioteca/manuales-y-silabus/Gui%CC%81aPedago%CC%81gicaCuadernosDeJurisprudencia_12-02-2024.pdf',
      );
    }
  }, []);

  const { t, i18n } = useTranslation('home');
  useTitle('Transformando la enseñanza del derecho en México');
  useGoogleAnalytics();
  return (
    <section id="content">
      <div className="content-wrap py-0">
        <Slider
          slidesArray={[
            {
              cover: especialidad,
              alt: 'Especialidad en Educación Jurídica',
              url: 'https://especialidad.ceead.org.mx/',
            },
            {
              cover: semilla,
              alt: 'Resultados semilla',
              url: resultadosSemilla,
            },
            {
              cover: congreso,
              alt: 'Congreso CEEAD 2025',
              url: 'https://congreso.ceead.org.mx/',
            },
            {
              cover: premiosEngrane,
              alt: 'Premios Engrane 2025',
              url: 'https://congreso.ceead.org.mx/premios-engrane',
            },
            {
              cover: Informe2023,
              alt: 'Informe Anual CEEAD 2023',
              url: 'https://media.ceead.org.mx/biblioteca/informes-anuales-y-boletines/CEEAD_InformeAnual2023_digital.pdf',
            },
            {
              cover: Cuadernos,
              alt: 'Cuadernos de Jurisprudencia del CEC',
              url: 'https://media.ceead.org.mx/biblioteca/manuales-y-silabus/Gui%CC%81aPedago%CC%81gicaCuadernosDeJurisprudencia_12-02-2024.pdf',
            },
            {
              cover: Jornadas,
              alt: 'Jornadas de sensibilización del Nuevo Modelo de Justicia Laboral',
              url: 'https://poa.ceead.org.mx',
            },
            { cover: slideNovedades, alt: 'Novedades editoriales', url: '/como-transformamos/biblioteca-digital' },
            { cover: i18n.language === 'en' ? slide1es : slide1es },
            { cover: i18n.language === 'en' ? slide2es : slide2es },
          ]}
        />
        <Link to="/quienes-somos">
          <SectionImage backgroundImage={quienesSomos} padding="200px 0">
            <div className="emphasis-title">
              <h2 className="m-0 font-weight-bold">{t('quienesSomos')}</h2>
            </div>
          </SectionImage>
        </Link>

        <div className="heading-block text-center">
          <h2>{t('comoTransformamos')}</h2>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4 p-0">
            <Link to="/como-transformamos/herramientas-diagnostico">
              <img className="w-100" src={i18n.language === 'en' ? herramientasEn : herramientas} alt="herramientas" />
            </Link>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 p-0">
            <Link to="/como-transformamos/investigacion">
              <img className="w-100" src={i18n.language === 'en' ? investigaonEn : investigaon} alt="investigacion" />
            </Link>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 p-0">
            <Link to="/como-transformamos/capacitacion-y-formacion">
              <img className="w-100" src={i18n.language === 'en' ? capactiacionEn : capactiacion} alt="capacitacion" />
            </Link>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 p-0">
            <Link to="/como-transformamos/eventos-institucionales">
              <img className="w-100" src={i18n.language === 'en' ? eventosEn : eventos} alt="eventos" />
            </Link>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 p-0">
            <Link to="/como-transformamos/redes-colaboracion">
              <img className="w-100" src={i18n.language === 'en' ? redesEn : redes} alt="redes" />
            </Link>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 p-0">
            <Link to="/como-transformamos/evaluacion">
              <img className="w-100" src={i18n.language === 'en' ? evaluacionEn : evaluacion} alt="evaluacion" />
            </Link>
          </div>
        </div>
        <Link to="/como-transformamos/biblioteca-digital">
          <SectionImage backgroundImage={biblioteca} padding="200px 0">
            <div className="emphasis-title">
              <h2 className="m-0 font-weight-bold">{t('bibliotecaDigital')}</h2>
            </div>
          </SectionImage>
        </Link>

        <div className="heading-block pt-3 text-center">
          <h2>{t('noticiasCeead')}</h2>
        </div>
        <div className="container pb-5 mb-5 clearfix">
          {Noticias.slice(-3)
            .map((noticia, index) => (
              <NoticiaCard
                key={index}
                title={noticia.titulo}
                date={noticia.fecha}
                desc={noticia.reseña}
                img={noticia.portadaUrl}
              />
            ))
            .reverse()}
        </div>
      </div>
    </section>
  );
};
