import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../../hooks/useTitle';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';

export const AvisoPrivacidad = () => {
  const { t } = useTranslation('avisoPrivacidad');
  useTitle('Aviso de privacidad');
  useGoogleAnalytics();
  return (
    <section id="content">
      <div className="content-wrap py-5">
        <div className="container clearfix">
          <h2>Aviso de privacidad</h2>

          <p dangerouslySetInnerHTML={{ __html: t('intro') }} />

          <p className="font-weight-bold">{t('p1')}</p>

          <p>{t('p2')}</p>

          <p>{t('p3')}</p>

          <ul className="pl-5">
            <li>{t('l1')}</li>
            <li>{t('l2')}</li>
            <li>{t('l3')}</li>
            <li>{t('l4')}</li>
            <li>{t('l5')}</li>
          </ul>

          <p dangerouslySetInnerHTML={{ __html: t('p4') }} />

          <p>{t('p5')}</p>

          <p className="font-weight-bold">{t('p6')}</p>

          <p>{t('p7')}</p>

          <p className="font-weight-bold">{t('p8')}</p>

          <p>{t('p9')}</p>

          <p className="font-weight-bold">{t('p10')}</p>

          <p>{t('p11')}</p>

          <p className="font-weight-bold">{t('p12')}</p>

          <p>{t('p13')}</p>

          <p className="font-weight-bold">{t('p14')}</p>

          <p>{t('p15')}</p>

          <p dangerouslySetInnerHTML={{ __html: t('p16') }} />

          <p dangerouslySetInnerHTML={{ __html: t('p17') }} />

          <p className="font-weight-bold">{t('p18')}</p>

          <p>{t('p19')}</p>

          <p dangerouslySetInnerHTML={{ __html: t('p20') }} />

          <p className="font-weight-bold">{t('p21')}</p>

          <p dangerouslySetInnerHTML={{ __html: t('p22') }} />

          <p className="font-weight-bold">{t('p23')}</p>

          <p>{t('p24')}</p>

          <p dangerouslySetInnerHTML={{ __html: t('p25') }} />
        </div>
      </div>
    </section>
  );
};
