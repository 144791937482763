import ReactGA from 'react-ga';
import { TRACKING_ID } from '../settings/settings';

export function init() {
  // Enable debug mode on the local development environment
  // const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
  // ReactGA.initialize(TRACKING_ID, { debug: isDev })
  ReactGA.initialize(TRACKING_ID);
}

export function sendEvent(payload) {
  ReactGA.event(payload);
}

export function sendPageview(path) {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
}

export function modalView(title) {
  ReactGA.modalview(title);
}
