import { Provider } from 'react-redux';
import { store } from './store';
import { AppRouter } from './routers/AppRouter';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import TagManager from 'react-gtm-module';
import { GTM_ID, GTM_AUTH, GTM_PREVIEW } from './settings/settings';

const tagManagerArgs = {
  gtmId: GTM_ID,
  auth: GTM_AUTH,
  preview: GTM_PREVIEW,
};
TagManager.initialize(tagManagerArgs);

export const App = () => {
  return (
    <Provider store={store}>
      <ReactNotifications />
      <AppRouter />
    </Provider>
  );
};
