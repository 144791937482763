import blog from '../../../data/blog.json';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../hooks/useTitle';
import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics';
import 'react-quill/dist/quill.bubble.css';

const reverseBlog = blog.reverse();

export const Blog = () => {
  useTitle('Blog CEEAD');
  useGoogleAnalytics();
  const { t, i18n } = useTranslation('blogCeead');
  return (
    <section id="content">
      <div className="content-wrap py-0">
        <div className="container py-5 clearfix">
          <div className="row">
            <div className="col-12">
              <h1 className="dots">{t('titulo')}</h1>
            </div>
            {i18n.language === 'en' && (
              <div className="col-12 pb-4">
                <h3>Only available in Spanish</h3>
              </div>
            )}
          </div>
          <div className="row">
            <div className="postcontent col-lg-12 order-lg-last">
              <div id="posts" className="post-timeline">
                {reverseBlog.map((entrada) => (
                  <div className="entry" key={entrada.id}>
                    <div className="entry-timeline">
                      {entrada.fecha.substr(0, 2)}
                      <span>{entrada.fecha.substr(3, 3)}</span>
                      <div className="timeline-divider" />
                    </div>
                    <Link to={`/blog/${entrada.slug}`}>
                      <div className="entry-image shadow-lg">
                        <img src={entrada.portada} alt={entrada.titulo} />
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
