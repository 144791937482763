import { useState } from 'react';

const CarouselImage = ({ src, alt }) => {
  const [image, setImage] = useState('');
  import(`../../../assets/images/eventos/${src}`).then((item) => {
    setImage(item.default);
  });

  return <img className="d-block w-100" src={image} alt={alt} />;
};

export default CarouselImage;
