export const UI_SET_ERROR = '[UI] SET ERROR';
export const UI_REMOVE_ERROR = '[UI] REMOVE ERROR';
export const UI_START_LOADING = '[UI] START LOADING';
export const UI_FINISH_LOADING = '[UI] FINISH LOADING';
export const UI_SHOW_MODAL = '[UI] SHOW MODAL';
export const UI_HIDE_MODAL = '[UI] HIDE MODAL';

export const uiSetError = (msgError) => {
  return {
    type: UI_SET_ERROR,
    payload: msgError,
  };
};

export const uiRemoveError = () => {
  return {
    type: UI_REMOVE_ERROR,
  };
};

export const uiStartLoading = () => {
  return {
    type: UI_START_LOADING,
  };
};

export const uiFinishLoading = () => {
  return {
    type: UI_FINISH_LOADING,
  };
};

export const uiShowModal = (modalContent) => {
  return {
    type: UI_SHOW_MODAL,
    payload: modalContent,
  };
};

export const uiHideModal = () => {
  return {
    type: UI_HIDE_MODAL,
  };
};
