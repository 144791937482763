import { useTranslation } from 'react-i18next';
import { SectionImage } from '../../../ui/sections/SectionImage';

import banner from './images/banner.jpg';
import logoEngrane from './images/logo_premios_engrane.svg';
import logoCC2024 from './images/logo_CC2024.svg';

import { useTitle } from '../../../../hooks/useTitle';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';

export const EventosInstitucionales = () => {
  useTitle('Eventos institucionales');
  useGoogleAnalytics();
  const { t } = useTranslation('eventosInstitucionales');
  return (
    <section id="content">
      <div className="content-wrap py-0">
        <SectionImage backgroundImage={banner}>
          <div className="emphasis-title text-left">
            <h2 className="font-weight-bold">{t('titulo')}</h2>
            <h4>{t('subtitulo')}</h4>
          </div>
        </SectionImage>

        <div className="container clearfix">
          <div className="row">
            <div className="col">
              <h2 className="dots">{t('congreso.titulo')}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 m-auto">
              <p className="text-center">
                <img src={logoCC2024} alt="Congreso CEEAD" style={{ width: '80%' }} />
              </p>
            </div>
            <div className="col-md-9 my-auto">
              <p>{t('congreso.parrafo1')}</p>
              <p>{t('congreso.parrafo2')}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 aligncenter my-auto text-center">
              <h4>{t('congreso.masInfo')}</h4>
              <a
                href="https://congreso.ceead.org.mx"
                target="_blank"
                rel="noreferrer"
                className="btn btn-lg btn-secondary"
              >
                congreso.ceead.org.mx <i className="icon-arrow-alt-circle-right1" />
              </a>
            </div>
          </div>
        </div>

        <div className="container pt-5 clearfix">
          <div className="row pt-5">
            <div className="col">
              <h2 className="dots">{t('engrane.titulo')}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 m-auto">
              <p className="text-center">
                <img src={logoEngrane} alt="engrane" style={{ width: '80%' }} />
              </p>
            </div>
            <div className="col-md-9 my-auto">
              <p>{t('engrane.parrafo1')}</p>
              <p>{t('engrane.parrafo2')}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 aligncenter">
              <p>{t('engrane.parrafo3')}</p>
              <ul>
                <li>{t('engrane.item1')}</li>
                <li>{t('engrane.item2')}</li>
                <li>{t('engrane.item3')}</li>
                <li>{t('engrane.item4')}</li>
                <li>{t('engrane.item5')}</li>
              </ul>
            </div>
            <div className="col-md-6 aligncenter my-auto">
              <a
                href="https://congreso.ceead.org.mx/premios-engrane"
                target="_blank"
                rel="noreferrer"
                className="btn btn-lg btn-secondary"
              >
                {t('engrane.boton')} <i className="icon-arrow-alt-circle-right1" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
