export const AUTH_LOGIN = '[AUTH] LOGIN';
export const AUTH_LOGOUT = '[AUTH] LOGOUT';

export const login = (id, displayName, role, email) => {
  return {
    type: AUTH_LOGIN,
    payload: {
      id,
      displayName,
      role,
      email,
    },
  };
};

export const logout = () => {
  return {
    type: AUTH_LOGOUT,
  };
};
