import { useRef } from 'react';
// import validator from 'validator/es';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { API_URL, GOOGLE_SITE_KEY } from '../../settings/settings';
import { Store } from 'react-notifications-component';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';

export const FormularioBoletin = () => {
  const { t, i18n } = useTranslation('footer');
  const { register, handleSubmit, setValue, trigger, errors } = useForm();
  const recaptchaRef = useRef();
  const submitBtnRef = useRef();

  const onSubmit = (data, e) => {
    submitBtnRef.current.disabled = true;
    setTimeout(function () {
      axios
        .post(`${API_URL}/v1/usuarios-boletin/`, data)
        .then((response) => {
          if (response.status === 200) {
            Store.addNotification({
              title: t('boletin.titulo'),
              message: t('boletin.correcto'),
              type: 'success',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 8000,
                pauseOnHover: true,
              },
            });
            e.target.reset();
            e.target.elements.recaptcha.value = '';
          }
        })
        .catch((error) => {
          if (error.response?.status === 400) {
            const message =
              i18n.language === 'en'
                ? error.response.data.detail.en.toString()
                : error.response.data.detail.es.toString();
            Store.addNotification({
              title: t('boletin.titulo'),
              message,
              type: 'warning',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 8000,
                pauseOnHover: true,
              },
            });
          } else {
            Store.addNotification({
              title: t('boletin.titulo'),
              message: t('boletin.error'),
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 8000,
                pauseOnHover: true,
              },
            });
          }
        })
        .finally(() => {
          recaptchaRef.current.reset();
          submitBtnRef.current.disabled = false;
        });
    }, 1000);
  };

  return (
    <section className="bulletin">
      <div className="bulletin-container">
        <div className="bulletin-details">
          <h2>Suscríbete a nuestro boletín para recibir noticias y contenido de interés</h2>
          <p>
            Al suscribirme acepto los{' '}
            <span>
              <a href="/legales/terminos-y-condiciones">Términos y condiciones.</a>
            </span>
          </p>
        </div>
        <div className="bulletin-card-container">
          <form className="bulletin-card" onSubmit={handleSubmit(onSubmit)}>
            <label>
              {t('boletin.placeholder.nombre')}
              <input name="nombre" ref={register({ required: true })} />
              {errors.nombre && <div className="bulletin-error">{t('boletin.errores.nombre')}</div>}
            </label>
            <label>
              {t('boletin.placeholder.apellidos')}
              <input name="apellidos" ref={register({ required: true })} />
              {errors.apellidos && <div className="bulletin-error">{t('boletin.errores.apellidos')}</div>}
            </label>
            <label>
              {t('boletin.placeholder.email')}
              <input name="email" ref={register({ required: true })} />
              {errors.email && errors.email.type === 'required' && (
                <div className="bulletin-error">{t('boletin.errores.email')}</div>
              )}
              {errors.email && errors.email.type === 'isValidEmail' && (
                <div className="bulletin-error">{t('boletin.errores.emailInvalido')}</div>
              )}
            </label>
            <label>
              <input type="hidden" name="recaptcha" ref={register({ required: true })} />
              <ReCAPTCHA
                ref={recaptchaRef}
                style={{ transform: 'scale(0.77)', WebkitTransform: 'scale(0.77)', width: '0' }}
                sitekey={GOOGLE_SITE_KEY}
                onChange={(value) => {
                  setValue('recaptcha', value);
                  trigger('recaptcha');
                }}
                hl={i18n.language}
              />
              {errors.recaptcha && errors.recaptcha.type === 'required' && (
                <div className="bulletin-error">recaptcha {t('boletin.errores.email')}</div>
              )}
            </label>
            <div className="bulletin-button-container">
              <button className="bulletin-button" ref={submitBtnRef}>
                {t('boletin.boton')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
