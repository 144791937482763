import { useState } from 'react';
import seminario from '../../../../../data/seminario.json';
import { useTranslation } from 'react-i18next';
import { PublicacionSeminario } from './PublicacionSeminario';
import { WidgetCalendario } from '../../../calendario_ceead/WidgetCalendario';
import { useTitle } from '../../../../../hooks/useTitle';
import useGoogleAnalytics from '../../../../../hooks/useGoogleAnalytics';

export const Seminario = () => {
  useTitle('Blog Seminario CEEAD');
  useGoogleAnalytics();
  const { t, i18n } = useTranslation('investigacion');
  const [year, setYear] = useState(new Date().getFullYear());
  const handleYearSelect = (year) => {
    setYear(year);
  };
  return (
    <section id="content">
      <div className="content-wrap py-0">
        <div className="container py-5 clearfix">
          <div className="row">
            <div className="col">
              <h1 className="dots">{t('seminario.titulo')}</h1>
            </div>
            {i18n.language === 'en' && (
              <div className="col-12 pb-4">
                <h3>Only available in Spanish</h3>
              </div>
            )}
          </div>
          <div className="row pb-5">
            <div className="col-3" />
            <div className="col-9">
              <h3 className="text-red pr-4 font-weight-bold">{t('seminario.publicaciones')}</h3>
            </div>
          </div>
          <div className="row pb-6">
            <div className="postcontent col-lg-9 order-lg-last">
              <div id="posts" className="post-timeline">
                {seminario
                  .filter((item) => year === new Date(item.fecha).getFullYear())
                  .reverse()
                  .map((publicacion) => (
                    <PublicacionSeminario publicacion={publicacion} key={publicacion.id} />
                  ))}
              </div>
            </div>

            <div className="sidebar col-lg-3">
              <div className="sidebar-widgets-wrap">
                <div className="widget clearfix center">
                  <p>
                    <button
                      className={year === 2020 ? 'btn btn-lg btn-dark' : 'btn btn-lg btn-outline-dark'}
                      onClick={() => handleYearSelect(2020)}
                    >
                      2020
                    </button>
                  </p>
                  <p>
                    <button
                      className={year === 2019 ? 'btn btn-lg btn-dark' : 'btn btn-lg btn-outline-dark'}
                      onClick={() => handleYearSelect(2019)}
                    >
                      2019
                    </button>
                  </p>
                </div>
                <WidgetCalendario />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
