import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionImage } from '../../../ui/sections/SectionImage';

import banner from './images/investigacion_banner.jpg';
import fondoInvestigacion from './images/lineas_investigacioon.png';
import fondoSeminario from './images/blog_seminario_ceead.png';
import transformartes from './images/transformartes.jpg';
import fondoEstancias from './images/estancias_investigacion.png';
import fondoEscuelas from './images/escuelas_derecho_numeros.jpg';
import fondoUniversidadesIndigenas from './images/universidades_indigenas.jpg';
import ultimaConvocatoriaEstancias from '../../../../assets/pdf/Convocatoria_EstanciasDeInvestigaciónCEEAD 2022-2023.pdf';
import fondoCulturaLegalidad from './images/cultura_legalidad.jpg';
import i20221 from './images/infografias/PLED_2022.jpg';
import i20222 from './images/infografias/LED_2022.jpg';
import i20211 from './images/infografias/LED_2021-01.jpg';
import i20212 from './images/infografias/LED_2021-02.jpg';
import i20201 from './images/infografias/LED_2020-01.jpg';
import i20202 from './images/infografias/PLED_2020-02.jpg';
import i20191 from './images/infografias/LED_2019.jpg';
import i20181 from './images/infografias/LED_2018-01.jpg';
import i20171 from './images/infografias/LED_2017-01.jpg';
import i20161 from './images/infografias/LED_2016-01.jpg';
import i20162 from './images/infografias/LED_2016-02.png';
import i20151 from './images/infografias/LED_2015-01.jpg';
import i20152 from './images/infografias/LED_2015-02.jpg';
import i20131 from './images/infografias/LED_2013-01.jpg';
import i20132 from './images/infografias/LED_2013-02.jpg';
import { Link } from 'react-router-dom';
import { useTitle } from '../../../../hooks/useTitle';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';

export const Investigacion = () => {
  useTitle('Investigación');
  useGoogleAnalytics();
  const { t } = useTranslation('investigacion');
  let infografias;
  const [year, setYear] = useState(2022);
  const handleYearSelect = (year) => {
    setYear(year);
  };
  // const handleFormSubmit = (event) => {
  //     event.preventDefault();
  //     console.log('form submitted');
  // }
  switch (year) {
    case 2022:
      infografias = (
        <>
          <img style={{ width: '100%' }} src={i20222} alt="2022-2023" />
          <img style={{ width: '100%' }} src={i20221} alt="2022-2023" />
        </>
      );
      break;
    case 2021:
      infografias = (
        <>
          <img style={{ width: '100%' }} src={i20211} alt="2021-2022" />
          <img style={{ width: '100%' }} src={i20212} alt="2021-2022" />
        </>
      );
      break;
    case 2020:
      infografias = (
        <>
          <img style={{ width: '100%' }} src={i20201} alt="2020-2021" />
          <img style={{ width: '100%' }} src={i20202} alt="2020-2021" />
        </>
      );
      break;
    case 2019:
      infografias = <img style={{ width: '100%' }} src={i20191} alt="2019-2020" />;
      break;
    case 2018:
      infografias = <img style={{ width: '100%' }} src={i20181} alt="2018-2019" />;
      break;
    case 2017:
      infografias = <img style={{ width: '100%' }} src={i20171} alt="2017-2018" />;
      break;
    case 2016:
      infografias = (
        <>
          <img style={{ width: '100%' }} src={i20161} alt="2016-2017 1" />
          <img style={{ width: '100%' }} src={i20162} alt="2016-2017 2" />
        </>
      );
      break;
    case 2015:
      infografias = (
        <>
          <img style={{ width: '100%' }} src={i20151} alt="2015-2016 1" />
          <img style={{ width: '100%' }} src={i20152} alt="2015-2016 2" />
        </>
      );
      break;
    case 2013:
      infografias = (
        <>
          <img style={{ width: '100%' }} src={i20131} alt="2013-2014 1" />
          <img style={{ width: '100%' }} src={i20132} alt="2013-2014 2" />
        </>
      );
      break;
    default:
      infografias = (
        <>
          <img style={{ width: '100%' }} src={i20201} alt="2020-2021" />;
          <img style={{ width: '100%' }} src={i20202} alt="2020-2021" />;
        </>
      );
  }
  return (
    <section id="content">
      <div className="content-wrap py-0">
        <SectionImage backgroundImage={banner}>
          <div className="emphasis-title text-left">
            <h2 className="font-weight-bold">{t('titulo')}</h2>
            <h4>{t('descripcion')}</h4>
          </div>
        </SectionImage>

        <div
          className="row mt-5"
          style={{
            backgroundImage: `url(${fondoInvestigacion})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
          }}
        >
          <div className="col-12">
            <h2 className="text-center">{t('titulo1')}</h2>
            <h3 className="text-center">{t('subtitulo1')}</h3>
          </div>
          <div className="col-md-6 hidden-sm-down" />
          <div className="col-md-6 col-sm-12 font-weight-bold pl-5 pl-md-0 py-5">
            <p>{t('item1')}</p>
            <p>{t('item2')}</p>
            <p>{t('item3')}</p>
            <p>{t('item4')}</p>
            <p>{t('item5')}</p>
            <p>{t('item6')}</p>
            <p>{t('item7')}</p>
            <p>{t('item8')}</p>
            <p>{t('item9')}</p>
          </div>
        </div>

        <SectionImage backgroundImage={fondoSeminario} padding="40px 0" margin="0">
          <div className="row">
            <div className="col-12">
              <div className="emphasis-title text-left">
                <h2 className="dots dots-white font-weight-bold">{t('blogSeminario.titulo')}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-sm-12">
              <div className="emphasis-title text-left">
                <h4>{t('blogSeminario.descripcion')}</h4>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 text-center">
              <Link to="/blog" className="btn btn-lg mt-5 btn-outline-light">
                {t('blogSeminario.boton')} <i className="icon-arrow-alt-circle-right1" />
              </Link>
            </div>
          </div>
        </SectionImage>

        <div className="row">
          <div className="col-md-6 col-sm-12 p-5 vertical-middle">
            <p>{t('blogSeminario.parrafo1')}</p>
            <p dangerouslySetInnerHTML={{ __html: t('blogSeminario.parrafo2') }} />
          </div>
          <div className="col-md-6 col-sm-12">
            <img src={transformartes} alt="#transformartes" />
          </div>
        </div>

        <SectionImage backgroundImage={fondoEstancias} padding="40px 0" margin="0">
          <div className="row">
            <div className="col-12">
              <div className="emphasis-title text-left">
                <h2 className="dots dots-white font-weight-bold">{t('estanciasInvestigacion.titulo')}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-sm-12">
              <div className="emphasis-title text-left">
                <h4>{t('estanciasInvestigacion.descripcion')}</h4>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 text-center">
              <h4 className="font-weight-bold mt-3">{t('estanciasInvestigacion.mensaje')}</h4>
              <a
                href={ultimaConvocatoriaEstancias}
                target="_blank"
                rel="noreferrer"
                className="btn btn-lg btn-outline-light"
              >
                {t('estanciasInvestigacion.boton')} <i className="icon-arrow-alt-circle-right1" />
              </a>
            </div>
          </div>
        </SectionImage>

        <div className="container py-5 clearfix">
          <div className="row">
            <div className="col-12">
              <p>{t('estanciasInvestigacion.parrafo1')}</p>
              <div className="text-center">
                <Link to="/como-transformamos/investigacion/perfiles-historicos" className="btn btn-lg btn-secondary">
                  {t('estanciasInvestigacion.boton2')} <i className="icon-arrow-alt-circle-right1" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <SectionImage backgroundImage={fondoEscuelas} padding="50px 0">
          <div className="emphasis-title text-left">
            <h2 className="dots dots-white font-weight-bold">{t('escuelasDerecho.titulo')}</h2>
            <h4>{t('escuelasDerecho.descripcion1')}</h4>
            <br />
            <h4>{t('escuelasDerecho.descripcion2')}</h4>
          </div>
        </SectionImage>

        <div className="container py-5 clearfix">
          <div className="row">
            <div className="postcontent col-lg-9 order-lg-last">
              <div className="row">
                <div className="col-12">{infografias}</div>
              </div>
            </div>
            <div className="sidebar col-lg-3 border-md-right">
              <div className="sidebar-widgets-wrap">
                <div className="widget clearfix center">
                  <h4 className="text-center font-weight-bold">{t('consultaInfografias')}</h4>
                  <h4 className="text-center font-weight-bold">{t('cicloEscolar')}</h4>
                  <p>
                    <button
                      className={year === 2022 ? 'btn btn-lg btn-dark' : 'btn btn-lg btn-outline-dark'}
                      onClick={() => handleYearSelect(2022)}
                    >
                      2022 - 2023
                    </button>
                  </p>
                  <p>
                    <button
                      className={year === 2021 ? 'btn btn-lg btn-dark' : 'btn btn-lg btn-outline-dark'}
                      onClick={() => handleYearSelect(2021)}
                    >
                      2021 - 2022
                    </button>
                  </p>
                  <p>
                    <button
                      className={year === 2020 ? 'btn btn-lg btn-dark' : 'btn btn-lg btn-outline-dark'}
                      onClick={() => handleYearSelect(2020)}
                    >
                      2020 - 2021
                    </button>
                  </p>
                  <p>
                    <button
                      className={year === 2019 ? 'btn btn-lg btn-dark' : 'btn btn-lg btn-outline-dark'}
                      onClick={() => handleYearSelect(2019)}
                    >
                      2019 - 2020
                    </button>
                  </p>
                  <p>
                    <button
                      className={year === 2018 ? 'btn btn-lg btn-dark' : 'btn btn-lg btn-outline-dark'}
                      onClick={() => handleYearSelect(2018)}
                    >
                      2018 - 2019
                    </button>
                  </p>
                  <p>
                    <button
                      className={year === 2017 ? 'btn btn-lg btn-dark' : 'btn btn-lg btn-outline-dark'}
                      onClick={() => handleYearSelect(2017)}
                    >
                      2017 - 2018
                    </button>
                  </p>
                  <p>
                    <button
                      className={year === 2016 ? 'btn btn-lg btn-dark' : 'btn btn-lg btn-outline-dark'}
                      onClick={() => handleYearSelect(2016)}
                    >
                      2016 - 2017
                    </button>
                  </p>
                  <p>
                    <button
                      className={year === 2015 ? 'btn btn-lg btn-dark' : 'btn btn-lg btn-outline-dark'}
                      onClick={() => handleYearSelect(2015)}
                    >
                      2015 - 2016
                    </button>
                  </p>
                  <p>
                    <button
                      className={year === 2013 ? 'btn btn-lg btn-dark' : 'btn btn-lg btn-outline-dark'}
                      onClick={() => handleYearSelect(2013)}
                    >
                      2013 - 2014
                    </button>
                  </p>
                </div>

                {/* PENDIENTE DEFINIR QUE INFORMACION SE PUEDE CONSULTAR Y QUE INFORMACION SE PUEDE DESCARGAR */}
                {/* <div className="widget clearfix center"> */}
                {/*    <a href="http://34.94.87.75/institucion/busqueda/web" target="_blank" rel="noreferrer" className="btn btn-danger btn-block text-center"><i className="icon-database mr-2" /> { t('baseDatos') }</a> */}
                {/* </div> */}

                {/* <div className="widget quick-contact-widget form-widget clearfix"> */}
                {/*    <h3>{ t('contactanos') }</h3> */}
                {/*    <div className="form-result" /> */}
                {/*    <form className="quick-contact-form mb-0" onSubmit={ handleFormSubmit }> */}
                {/*        <input type="text" */}
                {/*               className="required sm-form-control input-block-level" */}
                {/*               name="nombreApellido" */}
                {/*               placeholder="Nombre y apellido" */}
                {/*        /> */}
                {/*        <input type="email" */}
                {/*               className="required sm-form-control email input-block-level" */}
                {/*               name="quick-contact-form-email" */}
                {/*               placeholder="Correo electronico" */}
                {/*        /> */}
                {/*        <textarea */}
                {/*                className="required sm-form-control input-block-level short-textarea" */}
                {/*                name="quick-contact-form-message" */}
                {/*                rows="4" cols="30" */}
                {/*                placeholder="Mensaje" */}
                {/*        /> */}
                {/*        <input type="text" */}
                {/*               className="d-none" */}
                {/*               name="quick-contact-form-botcheck" */}
                {/*        /> */}
                {/*        <input type="hidden" */}
                {/*               name="origin" */}
                {/*               value="investigacion" */}
                {/*        /> */}
                {/*        <button type="submit" */}
                {/*                className="button btn-block button-small rounded-pill m-0">{ t('enviaMensaje') } */}
                {/*        </button> */}
                {/*    </form> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="row my-5">
          <div className="col-md-6 col-sm-12">
            <SectionImage backgroundImage={fondoUniversidadesIndigenas} padding="250px 0" margin="0">
              <div className="emphasis-title text-center">
                <h2 className="dots dots-white font-weight-bold">{t('proyectosConcluidos.universidadesIndigenas')}</h2>
              </div>
            </SectionImage>
          </div>
          <div className="col-md-6 col-sm-12 vertical-middle">
            <div className="container py-5 clearfix">
              <div className="row">
                <div className="col-12">
                  <p>{t('proyectosConcluidos.parrafo1')}</p>
                  <p>{t('proyectosConcluidos.parrafo2')}</p>
                  <h4 className="font-weight-bold center">{t('proyectosConcluidos.consulta')}</h4>
                  <div className="center">
                    <Link to="/como-transformamos/biblioteca-digital" className="btn btn-lg btn-secondary">
                      {t('proyectosConcluidos.boton')} <i className="icon-arrow-alt-circle-right1" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-md-6 col-sm-12">
            <SectionImage backgroundImage={fondoCulturaLegalidad} padding="250px 0" margin="0">
              <div className="emphasis-title text-center">
                <h2 className="dots dots-white font-weight-bold">{t('proyectosConcluidos.culturaLegalidad')}</h2>
              </div>
            </SectionImage>
          </div>
          <div className="col-md-6 col-sm-12 vertical-middle">
            <div className="container py-5 clearfix">
              <div className="row">
                <div className="col-12">
                  <p>{t('proyectosConcluidos.parrafo3')}</p>
                  <p>{t('proyectosConcluidos.parrafo4')}</p>
                  <h4 className="font-weight-bold center">{t('proyectosConcluidos.consulta')}</h4>
                  <div className="center">
                    <Link to="/como-transformamos/biblioteca-digital" className="btn btn-lg btn-secondary">
                      {t('proyectosConcluidos.boton')} <i className="icon-arrow-alt-circle-right1" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
