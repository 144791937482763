import PropTypes from 'prop-types';

export const TituloCategoria = ({ backgroundImage, title }) => {
  const style = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    padding: '65px 0',
    width: '100%',
    height: '180px',
  };
  return (
    <div className="section m-0 border-top-0 dark" style={style}>
      <div className="container clearfix">
        <div className="emphasis-title left">
          {title && (
            <h2 className="font-weight-bold m-0" style={{ fontSize: '28px', color: 'white' }}>
              <i className="far fa-play-circle mr-3" /> {title}
            </h2>
          )}
        </div>
      </div>
    </div>
  );
};

TituloCategoria.propTypes = {
  backgroundImage: PropTypes.node.isRequired,
};
