import { SectionImage } from '../../../ui/sections/SectionImage';
import { TituloCategoria } from './TituloCategoria';
import titleBackground from './images/banner.jpg';
import competenciasPedagogicas from './images/competencias_pedagogicas.jpg';
import cursosComplementarios from './images/cursos_complementarios.jpg';
import derechosHumanos from './images/derechos_humanos.jpg';
import personalOperador from './images/personal_operador.jpg';
import sjpa from './images/reforma/sjpa.jpg';
import logoAulaCEEAD from '../../../../assets/images/logos/logo_Aula.svg';
import cpp from './images/reforma/cpp.jpg';
import ppa from './images/reforma/ppa.jpg';
import tlo from './images/reforma/tlo.jpg';
import peeded from './images/reforma/peeded.jpg';
import masc from './images/reforma/masc.jpg';
import exmida from './images/cejume/exmida.jpg';
import etica from './images/cejume/etica_cejume_2021.jpg';
import genero from './images/cejume/genero_cejume_2021.jpg';
import dh from './images/reforma/dh.jpg';
import champ from './images/cejume/champ.jpg';
// import chad from './images/cejume/chad.jpg';
import chapc from './images/cejume/chapc.jpg';
import exmasc from './images/cejume/exmasc.jpg';
import cejumeDerechosHumanos from './images/cejume/DERECHOS_HUMANOS.jpg';
import cejumeDogmaticaPenal from './images/cejume/DOGMATICA_PENAL_APLICADA.jpg';
import cejumeEjecucionPenal from './images/cejume/EJECUCION_PENAL.jpg';

// import placeholder from './images/curso_placeholder.png';

import capacitacionesPresenciales from './images/capacitaciones_presenciales.jpg';
import viajesEstudio from './images/viajes_estudio.jpg';

import { useTranslation } from 'react-i18next';
import { Tarjeta3d } from './Tarjeta3d';
import { useTitle } from '../../../../hooks/useTitle';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';

export const CapacitacionFormacion = () => {
  useTitle('Capacitación y formación');
  useGoogleAnalytics();
  const { t } = useTranslation('comoTransformamos');
  return (
    <section id="content">
      <div className="content-wrap py-0">
        <SectionImage backgroundImage={titleBackground}>
          <div className="emphasis-title text-left">
            <h2 className="font-weight-bold">{t('capacitacionFormacion.titulo')}</h2>
            <h4>{t('capacitacionFormacion.descripcion')}</h4>
          </div>
        </SectionImage>
        <div className="container clearfix mb-5">
          <div className="row my-5">
            <div className="col-12">
              <h2 className="dots">{t('capacitacionFormacion.capacitacionLinea')}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 p-5 vertical-middle">
              <a href="https://aulaceead.mx" target="_blank" rel="noreferrer" className="center">
                <img src={logoAulaCEEAD} alt="aula ceead" />
              </a>
            </div>
            <div className="col-md-8 vertical-middle">
              <p>{t('capacitacionFormacion.descripcionCapacitacion')}</p>
              <p>
                <b>{t('capacitacionFormacion.siguientesCursos')}</b>
              </p>
            </div>
          </div>
        </div>

        <TituloCategoria backgroundImage={sjpa} title={t('capacitacionFormacion.justiciaPenal')} />
        <div className="container my-0 px-5 py-5 pt-5 pb-5 clearfix">
          <div className="row row-cols-1 row-cols-md-3 row-cols-xl-3">
            <div className="col mb-2 p-3">
              <Tarjeta3d titulo={t('capacitacionFormacion.tlo')} image={tlo} />
            </div>
            <div className="col mb-2 p-3">
              <Tarjeta3d titulo={t('capacitacionFormacion.ppa')} image={ppa} />
            </div>
            <div className="col mb-2 p-3">
              <Tarjeta3d titulo={t('capacitacionFormacion.cpp')} image={cpp} />
            </div>
            <div className="col mb-2 p-3">
              <Tarjeta3d titulo={t('capacitacionFormacion.masc')} image={masc} />
            </div>
          </div>
        </div>

        <TituloCategoria
          backgroundImage={competenciasPedagogicas}
          title={t('capacitacionFormacion.competenciasPedagogicas')}
        />
        <div className="container my-0 px-5 py-5 pt-5 pb-5 clearfix">
          <div className="row row-cols-1 row-cols-md-3 row-cols-xl-3">
            <div className="col mb-2 p-3">
              <Tarjeta3d titulo={t('capacitacionFormacion.peeded')} image={peeded} />
            </div>
          </div>
        </div>

        <TituloCategoria backgroundImage={derechosHumanos} title={t('capacitacionFormacion.derechosHumanos')} />
        <div className="container my-0 px-5 py-5 pt-5 pb-5 clearfix">
          <div className="row row-cols-1 row-cols-md-3 row-cols-xl-3">
            <div className="col mb-2 p-3">
              <Tarjeta3d titulo={t('capacitacionFormacion.dh')} image={dh} />
            </div>
          </div>
        </div>

        <TituloCategoria
          backgroundImage={personalOperador}
          title={t('capacitacionFormacion.certificacionJusticia')}
          id="personal-operador"
        />
        <div className="container my-0 px-5 py-5 pt-5 pb-5 clearfix">
          <div className="row row-cols-1 row-cols-md-3 row-cols-xl-3">
            <div className="col mb-2 p-3">
              <Tarjeta3d titulo={t('capacitacionFormacion.exmida')} image={exmida} />
            </div>

            <div className="col mb-2 p-3">
              <Tarjeta3d titulo={t('capacitacionFormacion.champ')} image={champ} />
            </div>

            <div className="col mb-2 p-3">
              <Tarjeta3d titulo={t('capacitacionFormacion.exmasc')} image={exmasc} />
            </div>
            <div className="col mb-2 p-3">
              <Tarjeta3d titulo={t('capacitacionFormacion.hpc')} image={chapc} />
            </div>
            {/* <div className="col mb-2 p-3"> */}
            {/*    <Tarjeta3d titulo={ t( 'capacitacionFormacion.hdp' ) } image={ placeholder }/> */}
            {/* </div> */}
            {/* <div className="col mb-2 p-3"> */}
            {/*    <Tarjeta3d titulo={ t( 'capacitacionFormacion.hajv' ) } image={ placeholder }/> */}
            {/* </div> */}
            {/* <div className="col mb-2 p-3"> */}
            {/*    <Tarjeta3d titulo={ t( 'capacitacionFormacion.hfmasc' ) } image={ placeholder }/> */}
            {/* </div> */}
          </div>
        </div>

        <TituloCategoria
          backgroundImage={cursosComplementarios}
          title={t('capacitacionFormacion.complementariosCertificacionJusticia')}
        />
        <div className="container my-0 px-5 py-5 pt-5 pb-5 clearfix">
          <div className="row row-cols-1 row-cols-md-3 row-cols-xl-3">
            <div className="col mb-2 p-3">
              <Tarjeta3d titulo={t('capacitacionFormacion.genero')} image={genero} />
            </div>
            <div className="col mb-2 p-3">
              <Tarjeta3d titulo={t('capacitacionFormacion.etica')} image={etica} />
            </div>
            <div className="col mb-2 p-3">
              <Tarjeta3d titulo="Curso de Ejecución Penal" image={cejumeEjecucionPenal} />
            </div>
            <div className="col mb-2 p-3">
              <Tarjeta3d
                titulo="Curso de Derechos Humanos en el sistema de justicia penal acusatorio"
                image={cejumeDerechosHumanos}
              />
            </div>
            <div className="col mb-2 p-3">
              <Tarjeta3d titulo="Curso de Dogmática Penal Aplicada" image={cejumeDogmaticaPenal} />
            </div>
          </div>
        </div>

        <div className="container px-5 py-5 pt-5 pb-5 clearfix">
          <div className="text-center">
            <p>
              <b>{t('capacitacionFormacion.masInfo')}</b>
            </p>
            <p>
              <a href="https://aulaceead.mx" target="_blank" rel="noreferrer" className="btn btn-lg btn-secondary">
                www.aulaceead.mx <i className="icon-arrow-alt-circle-right1" />
              </a>
            </p>
          </div>
        </div>

        <div className="row mb-5" id="capacitaciones_presenciales">
          <div className="col-md-6">
            <SectionImage backgroundImage={capacitacionesPresenciales} padding="180px 0">
              <div className="emphasis-title">
                <h2 className="dots dots-white font-weight-bold m-0">
                  {t('capacitacionFormacion.capacitacionesPresenciales')}
                </h2>
              </div>
            </SectionImage>
          </div>
          <div className="col-md-6 px-5">
            <p>{t('capacitacionFormacion.capacitacionesPresencialesDescripcionParrafo1')}</p>
            <p>{t('capacitacionFormacion.capacitacionesPresencialesDescripcionParrafo2')}</p>
          </div>
        </div>
        <div className="row mb-5" id="viajes_estudio">
          <div className="col-md-6 px-5">
            <p>{t('capacitacionFormacion.viajesEstudioParrafo1')}</p>
            <p>{t('capacitacionFormacion.viajesEstudioParrafo2')}</p>
            <p>{t('capacitacionFormacion.viajesEstudioParrafo3')}</p>
          </div>
          <div className="col-md-6">
            <SectionImage backgroundImage={viajesEstudio} padding="180px 0">
              <div className="emphasis-title">
                <h2 className="dots dots-white font-weight-bold m-0">{t('capacitacionFormacion.viajesEstudio')}</h2>
              </div>
            </SectionImage>
          </div>
        </div>
      </div>
    </section>
  );
};
