import { useTitle } from '../../../../hooks/useTitle';
import { useTranslation } from 'react-i18next';

const Agradecimientos = ({ paypalId }) => {
  const { t } = useTranslation('apoyaCeead');
  useTitle(t('donacion.agradecimientoTitulo'));

  return (
    <div>
      <h3>{t('donacion.agradecimientoTitulo')}</h3>
      <p>{t('donacion.agradecimientoTexto')}</p>
      <p>{`Paypal ID: ${paypalId}`}</p>
    </div>
  );
};

export default Agradecimientos;
