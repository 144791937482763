import { useTranslation } from 'react-i18next';
import bg from './images/bg_estancias.jpg';
import im from './images/im_estancias.jpg';
import mc from './images/mc_estancias.jpg';
import mj from './images/mj_estancias.jpg';
import ma from './images/ma_estancias.jpg';
import ultimaConvocatoriaEstancias from '../../../../../assets/pdf/Convocatoria_Programa_de_Estancias_de_Investigacion_CEEAD_Ciclo_2020-2021.pdf';
import useGoogleAnalytics from '../../../../../hooks/useGoogleAnalytics';
import { useTitle } from '../../../../../hooks/useTitle';

export const PerfilesHistoricos = () => {
  useTitle('Perfiles históricos');
  useGoogleAnalytics();
  const { t } = useTranslation('perfilesHistoricos');
  return (
    <section id="content">
      <div className="content-wrap py-0 mb-6">
        <div className="container pt-5 clearfix">
          <div className="row">
            <div className="col">
              <h2 className="dots">{t('titulo')}</h2>
            </div>
          </div>
        </div>

        <div className="row align-items-stretch">
          <div
            className="col-md-4 col-padding min-vh-75"
            style={{ background: `url('${bg}') center center no-repeat`, backgroundSize: 'cover' }}
          />
          <div className="col-md-8 col-padding">
            <div>
              <h3 style={{ color: '#c80021', marginBottom: '5px' }}>{t('perfil1.nombre')}</h3>
              <span className="ml-4 mb-3 inline-block font-weight-medium">{t('perfil1.pais')}</span>
              <div className="row col-mb-50">
                <div className="col-lg-12">
                  <p>{t('perfil1.parrafo1')}</p>
                  <p>{t('perfil1.parrafo2')}</p>
                  <p>{t('perfil1.parrafo3')}</p>
                  <p>{t('perfil1.parrafo4')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row align-items-stretch">
          <div
            className="col-md-4 col-padding min-vh-75 order-md-last"
            style={{ background: `url('${im}') center center no-repeat`, backgroundSize: 'cover' }}
          />
          <div className="col-md-8 col-padding" style={{ backgroundColor: '#F5F5F5' }}>
            <div>
              <h3 style={{ color: '#c80021', marginBottom: '5px' }}>{t('perfil2.nombre')}</h3>
              <span className="ml-4 mb-3 inline-block font-weight-medium">{t('perfil2.pais')}</span>
              <div className="row col-mb-50">
                <div className="col-lg-12">
                  <p>{t('perfil2.parrafo1')}</p>
                  <p>{t('perfil2.parrafo2')}</p>
                  <p>{t('perfil2.parrafo3')}</p>
                  <p>{t('perfil2.parrafo4')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row align-items-stretch">
          <div
            className="col-md-4 col-padding min-vh-75"
            style={{ background: `url('${mc}') center center no-repeat`, backgroundSize: 'cover' }}
          />
          <div className="col-md-8 col-padding">
            <div>
              <h3 style={{ color: '#c80021', marginBottom: '5px' }}>{t('perfil3.nombre')}</h3>
              <span className="ml-4 mb-3 inline-block font-weight-medium">{t('perfil3.pais')}</span>
              <div className="row col-mb-50">
                <div className="col-lg-12">
                  <p>{t('perfil3.parrafo1')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row align-items-stretch">
          <div
            className="col-md-4 col-padding min-vh-75 order-md-last"
            style={{ background: `url('${mj}') center center no-repeat`, backgroundSize: 'cover' }}
          />
          <div className="col-md-8 col-padding" style={{ backgroundColor: '#F5F5F5' }}>
            <div>
              <h3 style={{ color: '#c80021', marginBottom: '5px' }}>{t('perfil4.nombre')}</h3>
              <span className="ml-4 mb-3 inline-block font-weight-medium">{t('perfil4.pais')}</span>
              <div className="row col-mb-50">
                <div className="col-lg-12">
                  <p>{t('perfil4.parrafo1')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row align-items-stretch">
          <div
            className="col-md-4 col-padding min-vh-75"
            style={{ background: `url('${ma}') center center no-repeat`, backgroundSize: 'cover' }}
          />
          <div className="col-md-8 col-padding">
            <div>
              <h3 style={{ color: '#c80021', marginBottom: '5px' }}>{t('perfil5.nombre')}</h3>
              <span className="ml-4 mb-3 inline-block font-weight-medium">{t('perfil5.pais')}</span>
              <div className="row col-mb-50">
                <div className="col-lg-12">
                  <p>{t('perfil5.parrafo1')}</p>
                  <p>{t('perfil5.parrafo2')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-6 clearfix center">
          <h4>{t('masInfo')}</h4>
          <a
            href={ultimaConvocatoriaEstancias}
            target="_blank"
            rel="noreferrer"
            className="btn btn-lg btn-outline-danger"
          >
            {t('boton')} <i className="icon-arrow-alt-circle-right1" />
          </a>
        </div>
      </div>
    </section>
  );
};
