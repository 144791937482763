import { COOKIE_DOMAIN, COOKIE_SECURE } from '../settings/settings';

export const setToken = (value) => {
  document.cookie = `__SecureAT7=${value};domain=${COOKIE_DOMAIN};path=/;max-age=2592000;samesite=strict;${COOKIE_SECURE}`;
};

export const getToken = () => {
  return document.cookie.replace(/(?:(?:^|.*;\s*)__SecureAT7\s*=\s*([^;]*).*$)|^.*$/, '$1');
};

export const removeToken = () => {
  document.cookie = `__SecureAT7=;max-age=-1;domain=${COOKIE_DOMAIN}`;
};
