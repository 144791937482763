import { useForm, Controller } from 'react-hook-form';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { API_URL } from '../../../../settings/settings';
import Swal from 'sweetalert2';

import Agradecimientos from './Agradecimientos';

export default function App() {
  const { t } = useTranslation('apoyaCeead');
  const [form, setForm] = useState({});
  const [showPaypal, setShowPaypal] = useState(false);
  const [showThanks, setShowThanks] = useState(false);
  const [usoCfdi, setUsoCfdi] = useState([]);
  const [regFiscal, setRegFiscal] = useState([]);
  const [paises, setPaises] = useState([]);
  const [estados, setEstados] = useState([]);
  const [municipiosFactura, setMunicipiosFactura] = useState([]);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    // watch,
    getValues,
    control,
  } = useForm({ mode: 'all', shouldUnregister: true });

  useEffect(() => {
    setValue('monto_otro', '');
  }, [getValues('monto')]);

  useEffect(() => {
    axios.get(`${API_URL}/v1/catalogos/paises/?limit=0`).then((response) => {
      if (response.status === 200) {
        const data = response.data.data.map((item) => ({ value: item.id, label: item.nombre }));
        setPaises(data);
      }
    });
  }, []);

  useEffect(() => {
    getCatalogo('USOCFDI').then((catalogo) => {
      catalogo !== undefined && setUsoCfdi(catalogo);
    });
    getCatalogo('REGFISCAL').then((catalogo) => {
      catalogo !== undefined && setRegFiscal(catalogo);
    });
    getEstados(151).then((estados) => {
      estados !== undefined && setEstados(estados);
    });
  }, []);

  async function getCatalogo(identificador) {
    const result = await axios.get(`${API_URL}/v1/catalogos/generales/identificador/${identificador}`).catch(() => {});
    if (result?.status === 200) {
      return result.data.map((item) => ({ value: item.id, label: item.nombre }));
    }
  }

  async function getEstados(paisId) {
    const result = await axios.get(`${API_URL}/v1/catalogos/estados/pais/${paisId}`).catch(() => {});
    if (result?.status === 200) {
      return result.data.map((item) => ({ value: item.id, label: item.nombre }));
    }
  }

  async function getMunicipios(estadoId) {
    if (estadoId) {
      const result = await axios.get(`${API_URL}/v1/catalogos/municipios/estado/${estadoId}`).catch(() => {});
      if (result?.status === 200) {
        return result.data.map((item) => ({ value: item.id, label: item.nombre }));
      }
    }
  }

  // const watchFields = watch();

  const onSubmit = async (data) => {
    data.monto = data.monto === 'otro' ? data.monto_otro : data.monto;
    delete data.monto_otro;
    data.solicita_factura = data.solicita_factura === 'si';
    setForm(data);
    setShowPaypal(true);
    window.scrollTo(0, 0);
  };

  const postForm = async (data) => {
    await axios.post(`${API_URL}/v1/donaciones`, data);
  };

  const invalidEmail = t('donacion.formulario.correoInvalido');
  const requeriedMessage = t('donacion.formulario.requerido');
  const invalidAmount = t('donacion.formulario.montoInvalido');

  return (
    <>
      {!showPaypal ? (
        <div className="row">
          <div className="col">
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <pre>{JSON.stringify(watchFields, null, 2)}</pre> */}
              <div className="row">
                <div className="col-md form-group">
                  <label htmlFor="nombre">{t('donacion.formulario.nombre')}</label>
                  <input
                    type="text"
                    className={`form-control${errors.nombre ? ' invalidInputform' : ''}`}
                    name="nombre"
                    ref={register({ required: requeriedMessage, maxLength: 80 })}
                  />
                  {errors.nombre && <div className="invalid-feedback d-block">{errors.nombre.message}</div>}
                </div>
                <div className="col-md form-group">
                  <label htmlFor="primer_apellido">{t('donacion.formulario.primerApellido')}</label>
                  <input
                    type="text"
                    className={`form-control${errors.primer_apellido ? ' invalidInputform' : ''}`}
                    name="primer_apellido"
                    ref={register({ required: requeriedMessage, maxLength: 80 })}
                  />
                  {errors.primer_apellido && (
                    <div className="invalid-feedback d-block">{errors.primer_apellido.message}</div>
                  )}
                </div>
                <div className="col-md form-group">
                  <label htmlFor="segundo_apellido">{t('donacion.formulario.segundoApellido')}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="segundo_apellido"
                    ref={register({ maxLength: 80 })}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md form-group">
                  <label htmlFor="fecha_nacimiento">{t('donacion.formulario.fechaNacimiento')}</label>
                  <Controller
                    as={InputMask}
                    className="form-control"
                    control={control}
                    mask="99/99/9999"
                    name="fecha_nacimiento"
                    defaultValue=""
                    placeholder={t('donacion.formulario.fechaNacimientoPlaceholder')}
                  />
                </div>
                <div className="col-md form-group">
                  <label htmlFor="email">{t('donacion.formulario.email')}</label>
                  <input
                    type="text"
                    className={`form-control${errors.email ? ' invalidInputform' : ''}`}
                    name="email"
                    ref={register({
                      required: requeriedMessage,
                      pattern: { value: /^\S+@\S+$/i, message: 'Correo electrónico inválido' },
                    })}
                  />
                  {errors.email && <div className="invalid-feedback d-block">{errors.email.message}</div>}
                </div>
                <div className="col-md form-group">
                  <label htmlFor="telefono">{t('donacion.formulario.telefono')}</label>
                  <input
                    type="tel"
                    className={`form-control${errors.telefono ? ' invalidInputform' : ''}`}
                    name="telefono"
                    ref={register({ maxLength: 12 })}
                    maxLength={12}
                  />
                </div>
                <div className="col-md form-group">
                  <label htmlFor="ocupacion">{t('donacion.formulario.ocupacion')}</label>
                  <input type="text" className="form-control" name="ocupacion" ref={register()} />
                </div>
              </div>
              <div className="row">
                <div className="col-md form-group">
                  <label htmlFor="pais">{t('donacion.formulario.pais')}</label>
                  <select
                    className={`form-control${errors.pais ? ' invalidInputform' : ''}`}
                    name="pais"
                    ref={register()}
                    defaultValue=""
                    onChange={() => {
                      setValue('estado', '');
                    }}
                  >
                    <option value="" disabled>
                      {t('donacion.formulario.opciones.selecciona')}
                    </option>
                    {paises.map((option) => {
                      return (
                        <option key={option.value} value={option.label}>
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col-md form-group">
                  <label htmlFor="estado">{t('donacion.formulario.estado')}</label>
                  <select
                    className={`form-control${errors.estado ? ' invalidInputform' : ''}`}
                    name="estado"
                    ref={register()}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      {t('donacion.formulario.opciones.selecciona')}
                    </option>
                    {getValues('pais') === 'México' &&
                      estados.map((option) => {
                        return (
                          <option key={option.value} value={option.label}>
                            {option.label}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-md form-group">
                  <label htmlFor="acerca_donativo">{t('donacion.formulario.aboutCeead')}</label>
                  <select
                    className={`form-control${errors.acerca_donativo ? ' invalidInputform' : ''}`}
                    name="acerca_donativo"
                    ref={register({ required: requeriedMessage })}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      {t('donacion.formulario.opciones.selecciona')}
                    </option>
                    <option value="Aula CEEAD">{t('donacion.formulario.opciones.aula')}</option>
                    <option value="Congreso CEEAD">{t('donacion.formulario.opciones.congreso')}</option>
                    <option value="Cursos y talleres">{t('donacion.formulario.opciones.cursos')}</option>
                    <option value="Diplomado">{t('donacion.formulario.opciones.diplomado')}</option>
                    <option value="Especialidad">{t('donacion.formulario.opciones.especialidad')}</option>
                    <option value="Otro">{t('donacion.formulario.opciones.otro')}</option>
                    <option value="Programa Semilla">{t('donacion.formulario.opciones.semilla')}</option>
                    <option value="Redes sociales">{t('donacion.formulario.opciones.redes')}</option>
                    <option value="Website CEEAD">{t('donacion.formulario.opciones.web')}</option>
                  </select>
                  {errors.acerca_donativo && (
                    <div className="invalid-feedback d-block">{errors.acerca_donativo.message}</div>
                  )}
                </div>
                <div className="col-md form-group">
                  <label htmlFor="acerca_donativo_otro">{t('donacion.formulario.opciones.otro')}</label>
                  <input
                    type="text"
                    className={`form-control${errors.acerca_donativo_otro ? ' invalidInputform' : ''}`}
                    name="acerca_donativo_otro"
                    ref={register({ required: getValues('acerca_donativo') === 'Otro' ? requeriedMessage : false })}
                    disabled={getValues('acerca_donativo') !== 'Otro'}
                  />
                  {getValues('acerca_donativo') === 'Otro' && errors.acerca_donativo_otro && (
                    <div className="invalid-feedback d-block">{errors.acerca_donativo_otro.message}</div>
                  )}
                </div>
              </div>
              <label>
                {t('donacion.formulario.monto')}(<small>MXN</small>)
              </label>
              <div className="form-group">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="100"
                    id="radio100"
                    name="monto"
                    ref={register({ required: requeriedMessage })}
                  />
                  <label className="form-check-label" htmlFor="radio100">
                    $100
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="monto"
                    value="300"
                    id="radio300"
                    ref={register({ required: requeriedMessage })}
                  />
                  <label className="form-check-label" htmlFor="radio300">
                    $300
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="monto"
                    value="500"
                    id="radio500"
                    ref={register({ required: requeriedMessage })}
                  />
                  <label className="form-check-label" htmlFor="radio500">
                    $500
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="monto"
                    value="1000"
                    id="radio1000"
                    ref={register({ required: requeriedMessage })}
                  />
                  <label className="form-check-label" htmlFor="radio1000">
                    $1000
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="monto"
                    value="otro"
                    id="radioOtro"
                    ref={register({ required: requeriedMessage })}
                  />
                  <label className="form-check-label" htmlFor="radioOtro">
                    {t('donacion.formulario.opciones.otro')}
                  </label>
                </div>
                {errors.monto && <div className="invalid-feedback d-block">{errors.monto.message}</div>}
              </div>
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">$</div>
                  </div>
                  <input
                    type="number"
                    className={`form-control${
                      getValues('monto') === 'otro' && errors.monto_otro ? ' invalidInputform' : ''
                    }`}
                    name="monto_otro"
                    ref={register({
                      required: getValues('monto') === 'otro' ? requeriedMessage : false,
                      min: {
                        value: getValues('monto') === 'otro' ? 1 : 0,
                        message: invalidAmount, // JS only: <p>error message</p> TS only support string
                      },
                    })}
                    disabled={getValues('monto') !== 'otro'}
                  />
                  {getValues('monto') === 'otro' && errors.monto_otro && (
                    <div className="invalid-feedback d-block">{errors.monto_otro.message}</div>
                  )}
                </div>
              </div>
              <div className="form-group mb-4">
                <label htmlFor="solicita_factura">{t('donacion.formulario.recibo')}</label>
                <select className="form-control" name="solicita_factura" ref={register({ required: requeriedMessage })}>
                  <option value="no">{t('donacion.formulario.opciones.no')}</option>
                  <option value="si">{t('donacion.formulario.opciones.si')}</option>
                </select>
              </div>

              {/* solicita_factura */}

              {getValues('solicita_factura') === 'si' && (
                <div className="card bg-light p-4">
                  <div className="row">
                    <div className="col-md form-group">
                      <label htmlFor="factura_nombre_razon_social">Razón social</label>
                      <input
                        type="text"
                        className={`form-control${errors.factura_nombre_razon_social ? ' invalidInputform' : ''}`}
                        name="factura_nombre_razon_social"
                        ref={register({ required: requeriedMessage })}
                      />
                      {errors.factura_nombre_razon_social && (
                        <div className="invalid-feedback d-block">{errors.factura_nombre_razon_social.message}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md form-group">
                      <label htmlFor="factura_rfc">RFC</label>
                      <input
                        type="text"
                        className={`form-control${errors.factura_rfc ? ' invalidInputform' : ''}`}
                        name="factura_rfc"
                        ref={register({ required: requeriedMessage })}
                      />
                      {errors.factura_rfc && (
                        <div className="invalid-feedback d-block">{errors.factura_rfc.message}</div>
                      )}
                    </div>
                    <div className="col-md form-group">
                      <label htmlFor="factura_regimen_fiscal">Régimen fiscal</label>
                      <select
                        className={`form-control${errors.factura_regimen_fiscal ? ' invalidInputform' : ''}`}
                        name="factura_regimen_fiscal"
                        ref={register({ required: requeriedMessage })}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          {t('donacion.formulario.opciones.selecciona')}
                        </option>
                        {regFiscal.map((option) => {
                          return (
                            <option key={option.value} value={option.label}>
                              {option.label}
                            </option>
                          );
                        })}
                      </select>
                      {errors.factura_regimen_fiscal && (
                        <div className="invalid-feedback d-block">{errors.factura_regimen_fiscal.message}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md form-group">
                      <label htmlFor="factura_uso_cfdi">Uso del CFDI</label>
                      <select
                        className={`form-control${errors.factura_uso_cfdi ? ' invalidInputform' : ''}`}
                        name="factura_uso_cfdi"
                        ref={register({ required: requeriedMessage })}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          {t('donacion.formulario.opciones.selecciona')}
                        </option>
                        {usoCfdi.map((option) => {
                          return (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          );
                        })}
                      </select>
                      {errors.factura_uso_cfdi && (
                        <div className="invalid-feedback d-block">{errors.factura_uso_cfdi.message}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md form-group">
                      <label htmlFor="factura_email">Correo electrónico</label>
                      <input
                        type="text"
                        className={`form-control${errors.factura_email ? ' invalidInputform' : ''}`}
                        name="factura_email"
                        ref={register({
                          required: requeriedMessage,
                          pattern: {
                            value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                            message: invalidEmail,
                          },
                        })}
                      />
                      {errors.factura_email && (
                        <div className="invalid-feedback d-block">{errors.factura_email.message}</div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md form-group">
                      <label htmlFor="factura_codigo_postal">Código postal</label>
                      <input
                        type="text"
                        className={`form-control${errors.factura_codigo_postal ? ' invalidInputform' : ''}`}
                        name="factura_codigo_postal"
                        ref={register({ required: requeriedMessage })}
                      />
                      {errors.factura_codigo_postal && (
                        <div className="invalid-feedback d-block">{errors.factura_codigo_postal.message}</div>
                      )}
                    </div>

                    <div className="col-md form-group">
                      <label htmlFor="factura_estado">Estado</label>
                      <select
                        className={`form-control${errors.factura_estado ? ' invalidInputform' : ''}`}
                        name="factura_estado"
                        ref={register({ required: requeriedMessage })}
                        defaultValue=""
                        onChange={() => {
                          getMunicipios(estados.find((item) => item.label === getValues('factura_estado')).value).then(
                            (municipios) => {
                              municipios !== undefined && setMunicipiosFactura(municipios);
                            },
                          );
                          setValue('factura_municipio', '');
                        }}
                      >
                        <option value="" disabled>
                          {t('donacion.formulario.opciones.selecciona')}
                        </option>
                        {estados.map((option) => {
                          return (
                            <option key={option.value} value={option.label}>
                              {option.label}
                            </option>
                          );
                        })}
                      </select>
                      {errors.factura_estado && (
                        <div className="invalid-feedback d-block">{errors.factura_estado.message}</div>
                      )}
                    </div>
                    <div className="col-md form-group">
                      <label htmlFor="factura_municipio">Municipio</label>
                      <select
                        className={`form-control${errors.factura_estado ? ' invalidInputform' : ''}`}
                        name="factura_municipio"
                        ref={register({ required: requeriedMessage })}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          {t('donacion.formulario.opciones.selecciona')}
                        </option>
                        {municipiosFactura.map((option) => {
                          return (
                            <option key={option.value} value={option.label}>
                              {option.label}
                            </option>
                          );
                        })}
                      </select>
                      {errors.factura_municipio && (
                        <div className="invalid-feedback d-block">{errors.factura_municipio.message}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md form-group">
                      <label htmlFor="factura_calle">Calle</label>
                      <input
                        type="text"
                        className={`form-control${errors.factura_calle ? ' invalidInputform' : ''}`}
                        name="factura_calle"
                        ref={register({ required: requeriedMessage })}
                      />
                      {errors.factura_calle && (
                        <div className="invalid-feedback d-block">{errors.factura_calle.message}</div>
                      )}
                    </div>
                    <div className="col-md form-group">
                      <label htmlFor="factura_colonia">Colonia</label>
                      <input
                        type="text"
                        className={`form-control${errors.factura_colonia ? ' invalidInputform' : ''}`}
                        name="factura_colonia"
                        ref={register({ required: requeriedMessage })}
                      />
                      {errors.factura_colonia && (
                        <div className="invalid-feedback d-block">{errors.factura_colonia.message}</div>
                      )}
                    </div>
                    <div className="col-md form-group">
                      <label htmlFor="factura_num_ext">Número exterior</label>
                      <input
                        type="text"
                        className={`form-control${errors.factura_num_ext ? ' invalidInputform' : ''}`}
                        name="factura_num_ext"
                        ref={register({ required: requeriedMessage })}
                      />
                      {errors.factura_num_ext && (
                        <div className="invalid-feedback d-block">{errors.factura_num_ext.message}</div>
                      )}
                    </div>
                    <div className="col-md form-group">
                      <label htmlFor="factura_num_int">Número interior</label>
                      <input
                        type="text"
                        className={`form-control${errors.factura_num_int ? ' invalidInputform' : ''}`}
                        name="factura_num_int"
                        ref={register()}
                      />
                      {errors.factura_num_int && (
                        <div className="invalid-feedback d-block">{errors.factura_num_int.message}</div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <input
                type="submit"
                className="btn btn-primary btn-lg btn-block mt-3"
                value="Proceder al pago con PayPal"
              />
            </form>
          </div>
        </div>
      ) : showThanks ? (
        <Agradecimientos paypalId={form.paypal_payment_id} />
      ) : (
        <div className="row">
          <div className="col center">
            <h3 className="text-center pb-3">{t('donacion.titulo')}</h3>
            <div className="zIndexDefault">
              <PayPalButtons
                style={{ layout: 'vertical' }}
                createOrder={(data, actions) => {
                  return actions.order
                    .create({
                      purchase_units: [
                        {
                          description: 'Donativo CEEAD',
                          amount: {
                            value: form.monto,
                          },
                        },
                      ],
                      application_context: {
                        brand_name: 'CEEAD, A.C.',
                        user_action: 'PAY_NOW',
                        shipping_preference: 'NO_SHIPPING',
                      },
                    })
                    .then((orderID) => {
                      return orderID;
                    });
                }}
                onApprove={(data, actions) => {
                  return actions.order?.capture().then(async function (details) {
                    try {
                      form.paypal_order_details = JSON.stringify(details);
                      form.paypal_order_id = details.id;
                      form.paypal_status = details.status;
                      form.paypal_date = details.update_time;
                      form.paypal_payment_id = details.purchase_units[0].payments.captures[0].id;
                      await postForm(form);
                      setShowThanks(true);
                    } catch (err) {
                      console.error(err);
                    }
                  });
                }}
                onError={async (err) => {
                  const payload = {
                    nombre: form.nombre,
                    primer_apellido: form.primer_apellido,
                    segundo_apellido: form.segundo_apellido,
                    email: form.email,
                    telefono: form.telefono,
                    log: err.message,
                  };
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Ocurrió un error al procesar el pago, intentalo de nuevo',
                  });
                  console.error(payload);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
