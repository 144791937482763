import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { API_URL } from '../../../settings/settings';

export const VerificarSuscripcionBoletin = ({ match }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation('verificarBoletin');
  const [mensaje, setMensaje] = useState({ class: 'default', content: t('verificando') });
  const token = match.params.token;
  if (!token) {
    history.push('/');
  }

  const verificar = useCallback(() => {
    axios
      .post(`${API_URL}/v1/usuarios-boletin/confirm/${token}`, {})
      .then((_res) => {
        setMensaje({ class: 'success', content: t('correcto') });
      })
      .catch((error) => {
        setMensaje({
          class: 'warning',
          content: error.response.data.message,
        });
      });
  }, [t, i18n, token]);

  useEffect(() => {
    verificar();
  }, [verificar]);

  return (
    <section id="content">
      <div className="content-wrap py-0 mb-5">
        <div className="container py-5 clearfix">
          <div className="row pt-5">
            <div className="col-12" style={{ height: '100vh' }}>
              <h2 className={`text-center text-${mensaje.class}`}>{mensaje.content}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
