import PropTypes from 'prop-types';

export const SectionImage = ({ backgroundImage, padding = '30px 0', margin = '4rem 0', children }) => {
  return (
    <div
      className="section dark mt-0 border-0"
      style={{
        margin,
        padding,
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div className="container center clearfix">{children}</div>
    </div>
  );
};

SectionImage.propTypes = {
  backgroundImage: PropTypes.node.isRequired,
};
