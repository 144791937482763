// import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { FormularioRecibo } from './FormularioRecibo';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useTitle } from '../../../../hooks/useTitle';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';
import Form from './Form';
import { PAYPAL_CLIENT_ID } from '../../../../settings/settings';

export const Donaciones = () => {
  useTitle('Ayúdanos a consolidar el Estado de derecho, la democracia y la justicia social en México');
  useGoogleAnalytics();
  const { t } = useTranslation('apoyaCeead');

  return (
    <PayPalScriptProvider
      options={{
        'client-id': PAYPAL_CLIENT_ID,
        currency: 'MXN',
        locale: 'es_MX',
      }}
    >
      <section id="content">
        <div className="content-wrap py-0">
          <div className="container clearfix mt-5 mb-5">
            <div className="row">
              <div className="col-12">
                <h1 className="dots">{t('titulo')}</h1>
              </div>
            </div>
            <Form />
          </div>
        </div>
      </section>
    </PayPalScriptProvider>
  );
};
