import { useTranslation } from 'react-i18next';
import bannerEs from './images/banner_es.jpg';
import bannerEn from './images/banner_en.jpg';
import { Link } from 'react-router-dom';
import informe20082013 from './informes/es/ceead_informe_anual_2008_2013.pdf';
import informe2015 from './informes/es/ceead_informe_anual_2015.pdf';
import informe2016 from './informes/es/ceead_informe_anual_2016.pdf';
import informe2017 from './informes/es/ceead_informe_anual_2017.pdf';
import informe2018 from './informes/es/ceead_informe_anual_2018.pdf';
import informe2019 from './informes/es/ceead_informe_anual_2019.pdf';
import report20082013 from './informes/en/ceead_annual_report_2008_2013.pdf';
import report2015 from './informes/en/ceead_annual_report_2015.pdf';
import report2016 from './informes/en/ceead_annual_report_2016.pdf';
import report2017 from './informes/en/ceead_annual_report_2017.pdf';
import report2018 from './informes/en/ceead_annual_report_2018.pdf';
import report2019 from './informes/en/ceead_annual_report_2019.pdf';
import { useTitle } from '../../../hooks/useTitle';
import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics';

export const ApoyaCeead = () => {
  useTitle('Ayúdanos a consolidar el Estado de derecho, la democracia y la justicia social en México');
  useGoogleAnalytics();
  const { t, i18n } = useTranslation('apoyaCeead');
  return (
    <section id="content">
      <div className="content-wrap py-0">
        <div className="container clearfix mt-5 mb-5">
          <div className="row">
            <div className="col">
              <h2 className="dots">{t('titulo')}</h2>
            </div>
          </div>
        </div>
        <div className="">
          <img src={i18n.language === 'en' ? bannerEn : bannerEs} className="w-100" alt="banner" />
        </div>
        <div className="container clearfix my-5">
          <div className="row py-4">
            <div className="col center">
              <Link to="/apoya-ceead/donar" className="btn btn-lg btn-success rounded-pill center px-5">
                <i className="icon-hand-holding-heart mr-3" /> {t('botonDonacion')}
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h2 className="dots">{t('tituloTransparencia')}</h2>
              <h4 className="text-center pb-4">{t('consulltaIngresos')}</h4>
              <div className="col-12 pb-5 center">
                <div className="p-3 d-inline">
                  <a
                    href={i18n.language === 'en' ? report20082013 : informe20082013}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg btn-outline-dark mb-4"
                  >
                    2008
                  </a>
                </div>
                <div className="p-3 d-inline">
                  <a
                    href={i18n.language === 'en' ? report20082013 : informe20082013}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg btn-outline-dark mb-4"
                  >
                    2009
                  </a>
                </div>
                <div className="p-3 d-inline">
                  <a
                    href={i18n.language === 'en' ? report20082013 : informe20082013}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg btn-outline-dark mb-4"
                  >
                    2010
                  </a>
                </div>
                <div className="p-3 d-inline">
                  <a
                    href={i18n.language === 'en' ? report20082013 : informe20082013}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg btn-outline-dark mb-4"
                  >
                    2011
                  </a>
                </div>
                <div className="p-3 d-inline">
                  <a
                    href={i18n.language === 'en' ? report20082013 : informe20082013}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg btn-outline-dark mb-4"
                  >
                    2012
                  </a>
                </div>
                <div className="p-3 d-inline">
                  <a
                    href={i18n.language === 'en' ? report20082013 : informe20082013}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg btn-outline-dark mb-4"
                  >
                    2013
                  </a>
                </div>
                <div className="p-3 d-inline">
                  <a
                    href={i18n.language === 'en' ? report2015 : informe2015}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg btn-outline-dark mb-4"
                  >
                    2014
                  </a>
                </div>
                <div className="p-3 d-inline">
                  <a
                    href={i18n.language === 'en' ? report2015 : informe2015}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg btn-outline-dark mb-4"
                  >
                    2015
                  </a>
                </div>
                <div className="p-3 d-inline">
                  <a
                    href={i18n.language === 'en' ? report2016 : informe2016}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg btn-outline-dark mb-4"
                  >
                    2016
                  </a>
                </div>
                <div className="p-3 d-inline">
                  <a
                    href={i18n.language === 'en' ? report2017 : informe2017}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg btn-outline-dark mb-4"
                  >
                    2017
                  </a>
                </div>
                <div className="p-3 d-inline">
                  <a
                    href={i18n.language === 'en' ? report2018 : informe2018}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg btn-outline-dark mb-4"
                  >
                    2018
                  </a>
                </div>
                <div className="p-3 d-inline">
                  <a
                    href={i18n.language === 'en' ? report2019 : informe2019}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg btn-outline-dark mb-4"
                  >
                    2019
                  </a>
                </div>
              </div>
              <h4 className="text-center pt-3">{t('parrafo1')}</h4>
              <p className="text-center">{t('parrafo2')}</p>
              <p className="text-center">{t('parrafo3')}</p>
              <p className="text-center font-weight-bold">{t('parrafo4')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
