import contacto from './images/contacto.jpg';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../hooks/useTitle';
import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics';
import { FormularioContacto } from './FormularioContacto';

export const Contacto = () => {
  useTitle('Contacto');
  useGoogleAnalytics();
  const { t } = useTranslation('contacto');
  return (
    <section id="content">
      <div className="content-wrap py-0 mb-5">
        <div className="container py-5 clearfix">
          <div className="row">
            <div className="col-12">
              <h2 className="dots text-red">{t('titulo')}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <h3>{t('direcciones')}</h3>
              <h4 className="mb-2">Centro de Estudios sobre la Enseñanza y el Aprendizaje del Derecho, A.C.</h4>
              <p>
                Miguel Nieto 440 Sur
                <br />
                Col. Centro
                <br />
                Monterrey, Nuevo León
                <br />
                64000 México
              </p>
              {/* <h3>{t('horarioTitulo')}</h3>
              <p>{t('horario')}</p> */}
              <h3>{t('telefonos')}</h3>
              <p>Tel. +52 (81) 2055-0628 | (81) 1766-1198</p>
            </div>
            <div className="col-sm-12 col-md-6">
              <iframe
                title="CEEAD Office"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3595.9042879733443!2d-100.33137838452484!3d25.674467318470757!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662958c91d43d69%3A0x1b72d3144562a2f0!2sCEEAD%2C%20A.C.!5e0!3m2!1ses-419!2smx!4v1606166185512!5m2!1ses-419!2smx"
                width="100%"
                height="450"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
                className="shadow"
              />
            </div>
          </div>
        </div>
        <div className="container clearfix">
          <div className="row col-12">
            <h3>{t('contactoTitulo')}</h3>
          </div>

          <div className="row">
            <div className="col-md-6 order-last order-md-first">
              <FormularioContacto />
            </div>
            <div className="col-md-6 order-first order-md-last">
              <div className="row">
                <div className="col-12">
                  <div className="text-center">
                    <img src={contacto} alt="contacto" />
                  </div>
                  <div className="text-center">
                    <h4 className="mb-2">{t('correoInfo')}</h4>
                    <p>
                      <a href="mailto:info@ceead.org.mx">info@ceead.org.mx</a>
                    </p>
                    <h4 className="mb-2">{t('correoSoporte')}</h4>
                    <p>
                      <a href="mailto:soporte@ceead.org.mx">soporte@ceead.org.mx</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row mt-5 col-12">
            <h3>{t('directorio.titulo')}</h3>
          </div>
          <div className="row">
            <div className="col-12">
              <table className="table table-sm">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">{t('directorio.columna1')}</th>
                    <th scope="col">{t('directorio.columna3')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t('directorio.recepcionGeneral')}</td>
                    <td>628</td>
                  </tr>
                  <tr>
                    <td>{t('directorio.aula')}</td>
                    <td>633</td>
                  </tr>
                  <tr>
                    <td>{t('directorio.cci')}</td>
                    <td>635, 646, 647, 648</td>
                  </tr>
                  <tr>
                    <td>{t('directorio.vinculacion')}</td>
                    <td>674</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};
