// SERVER URLS
export const CEEAD_PATH = process.env.REACT_APP_CEEAD_PATH;
export const EXPO_PATH = process.env.REACT_APP_EXPO_PATH;
export const OBSERVATORIO_PATH = process.env.REACT_APP_OBSERVATORIO_PATH;
export const AULA_PATH = process.env.REACT_APP_AULA_PATH;
export const CONGRESO_PATH = process.env.REACT_APP_CONGRESO_PATH;
export const ACCOUNTS_PATH = process.env.REACT_APP_ACCOUNTS_PATH;
export const UNIVERSO_PATH = process.env.REACT_APP_UNIVERSO_PATH;
export const MY_ACCOUNT_PATH = process.env.REACT_APP_MY_ACCOUNT_PATH;
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
export const COOKIE_SECURE = process.env.REACT_APP_COOKIE_SECURE;

// API URLS
export const API_URL = process.env.REACT_APP_API_URL;
export const MEDIA = process.env.REACT_APP_MEDIA;

// ACCOUNTS PATHS
export const LOGIN_PATH = process.env.REACT_APP_LOGIN_PATH;
export const REGISTER_PATH = process.env.REACT_APP_REGISTER_PATH;

// I18N
export const I18N_KEY = process.env.REACT_APP_I18N_KEY;

// GOOGLE ANALYTICS TRACKING CODE
export const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;

// GOOGLE RECAPTCHA
export const GOOGLE_SITE_KEY = process.env.REACT_APP_GOOGLE_SITE_KEY;

// USER TYPES
export const ESTUDIANTE = 'ESTUDIANTE';
export const DOCENTE = 'DOCENTE';
export const PERSONAL_OPERADOR = 'PERSONAL OPERADOR';
export const PERSONAL_INVESTIGADOR = 'PERSONAL INVESTIGADOR';
export const ORGANIZACION = 'ORGANIZACION';
export const GENERAL = 'GENERAL';

// GOOGLE TAG MANAGER
export const GTM_ID = process.env.REACT_APP_GTM_ID;
export const GTM_AUTH = process.env.REACT_APP_GTM_AUTH;
export const GTM_PREVIEW = process.env.REACT_APP_GTM_PREVIEW;

export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
