import { AUTH_LOGIN, AUTH_LOGOUT } from '../actions/auth';

export const authReducer = (state = {}, action) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return {
        id: action.payload.id,
        displayName: action.payload.displayName,
        role: action.payload.role,
        email: action.payload.email,
      };
    case AUTH_LOGOUT:
      return {};
    default:
      return state;
  }
};
