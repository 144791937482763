import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { authReducer } from './reducers/authReducer';
import { uiModalReducer, uiReducer } from './reducers/uiReducer';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  modal: uiModalReducer,
});

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
