import { format } from 'date-fns';
import { es, enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { MEDIA } from '../../../../../settings/settings';

export const PublicacionSeminario = ({ publicacion }) => {
  const { i18n } = useTranslation();
  const date = new Date(publicacion.fecha);
  return (
    <div className="entry">
      <div className="entry-timeline">
        {format(date, 'dd', { locale: i18n.language === 'en' ? enUS : es })}
        <span>{format(date, 'MMM', { locale: i18n.language === 'en' ? enUS : es })}</span>
        <div className="timeline-divider" />
      </div>
      <div className="entry-image">
        <img src={MEDIA + publicacion.portadaUrl} alt={publicacion.titulo} className="shadow" />
      </div>
      <div className="entry-title">
        <h2 className="pointer">{publicacion.titulo}</h2>
      </div>
      {publicacion.autor && (
        <div className="entry-meta">
          <p className="mb-0">- {publicacion.autor}</p>
        </div>
      )}
      <div className="entry-meta">
        <ul>
          <li>
            <i className="icon-calendar-alt1" /> {format(date, 'PP', { locale: i18n.language === 'en' ? enUS : es })}
          </li>
        </ul>
      </div>
      <div className="entry-content">
        <p dangerouslySetInnerHTML={{ __html: publicacion.texto }} />
        <div className="entry-content">
          <a href={MEDIA + publicacion.url} className="">
            Descarga el artículo completo <i className="icon-line-download" />
          </a>
        </div>
      </div>
    </div>
  );
};
