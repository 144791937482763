import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from '../../../../hooks/useForm';
import validator from 'validator/es';

// NOTA: Este formulario debe enviarse a informacion@ceead.org.mx

export const Formulario = (props) => {
  const { t } = useTranslation('redesColaboracion');
  const [error, setError] = useState(false);
  const [formValues, handleInputChange] = useForm({
    nombreCompleto: '',
    email: '',
    institucion: '',
    red: '',
    razon: '',
  });
  const { nombreCompleto, email, institucion, red, razon } = formValues;
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(false);
    if (isFormValid()) {
      alert('Formulario enviado');
      props.onHide();
    }
  };
  const isFormValid = () => {
    if (nombreCompleto.trim().length === 0) {
      setError(true);
      return false;
    } else if (!validator.isEmail(email)) {
      setError(true);
      return false;
    } else if (institucion.trim().length === 0) {
      setError(true);
      return false;
    } else if (red.trim().length === 0) {
      setError(true);
      return false;
    } else if (razon.trim().length === 0) {
      setError(true);
      return false;
    }
    setError(false);
    return true;
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{t('formulario.titulo')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          {error && (
            <div className="alert alert-warning" role="alert">
              {t('formulario.mensajeError')}
            </div>
          )}
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="nombreCompleto">{t('formulario.nombreCompleto')}: </label>
              <input
                type="text"
                className="form-control"
                id="nombreCompleto"
                name="nombreCompleto"
                onChange={handleInputChange}
                value={nombreCompleto}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="correoElectronico">{t('formulario.correo')}: </label>
              <input
                type="text"
                className="form-control"
                id="correoElectronico"
                name="email"
                onChange={handleInputChange}
                value={email}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="institucion">{t('formulario.institucion')}: </label>
              <input
                type="text"
                className="form-control"
                id="institucion"
                name="institucion"
                onChange={handleInputChange}
                value={institucion}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="red">{t('formulario.red')}: </label>
              <select id="red" className="form-control" name="red" onChange={handleInputChange} value={red}>
                <option value="">{t('formulario.selecciona')}</option>
                <option value={1}>{t('redesTrabajo.item1')}</option>
                <option value={2}>{t('redesTrabajo.item2')}</option>
                <option value={3}>{t('redesTrabajo.item3')}</option>
              </select>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="razonIntegrarse">{t('formulario.razon')}</label>
            <textarea
              id="razonIntegrarse"
              className="form-control"
              name="razon"
              rows={3}
              onChange={handleInputChange}
              value={razon}
            />
          </div>
          <div className="text-right">
            <button type="submit" className="btn btn-primary">
              {t('formulario.boton')}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
