import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../../hooks/useTitle';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';
import consejo from '../../../../data/consejo.json';

export const Consejo = () => {
  useTitle('Nuestro consejo');
  useGoogleAnalytics();
  const { t, i18n } = useTranslation('quienesSomos');
  console.log(consejo);
  return (
    <section id="content">
      <div className="content-wrap py-0 mb-5">
        <div className="container pt-5 clearfix">
          <div className="row">
            <div className="col-12">
              <h2 className="dots text-red">{t('sinergias.consejoTitle')}</h2>
            </div>
          </div>
          <div className="row">
            {consejo.map((tipo, index) => (
              <div className="col-sm-12 col-md-6" key={index}>
                <h3>{i18n.language === 'en' ? tipo.tipo.en : tipo.tipo.es}</h3>
                {tipo.integrantes.map((miembro, index) => (
                  <div key={index}>
                    <strong className="mb-0">{miembro.nombre}</strong>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: i18n.language === 'en' ? miembro.posicion.en : miembro.posicion.es,
                      }}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
