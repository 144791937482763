import noticias from '../../../data/noticias.json';

import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../hooks/useTitle';
import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics';

export const Noticias = () => {
  useTitle('Noticias CEEAD');
  useGoogleAnalytics();
  const { t, i18n } = useTranslation('noticiasCeead');

  return (
    <section id="content">
      <div className="content-wrap py-0">
        <div className="container py-5 clearfix">
          <div className="row">
            <div className="col-12">
              <h1 className="dots">{t('titulo')}</h1>
            </div>
            {i18n.language === 'en' && (
              <div className="col-12 pb-4">
                <h3>Only available in Spanish</h3>
              </div>
            )}
          </div>
          <div className="row">
            <div className="postcontent col-lg-12 order-lg-last">
              <div id="posts" className="post-timeline">
                {noticias
                  .filter((noticia) => noticia.id > 432)
                  .reverse()
                  .map((noticia) => (
                    <div className="entry" key={noticia.id}>
                      <div className="entry-timeline">
                        {noticia.fecha.substr(0, 2)}
                        <span>{noticia.fecha.substr(3, 3)}</span>
                        <div className="timeline-divider" />
                      </div>
                      {noticia.portadaUrl && (
                        <div className="entry-image">
                          <img src={`https://media.ceead.org.mx${noticia.portadaUrl}`} alt={noticia.titulo} />
                        </div>
                      )}
                      <div className="entry-title">
                        <h2 dangerouslySetInnerHTML={{ __html: noticia.titulo }} />
                      </div>
                      <div className="entry-meta">
                        <ul>
                          <li>
                            <i className="icon-calendar3" />
                            {noticia.fecha}
                          </li>
                          <li>
                            <i className="icon-user" />
                            {noticia.autor}
                          </li>
                          {noticia.imagenes.length > 0 ? (
                            <li>
                              <i className="icon-camera-retro" />
                            </li>
                          ) : (
                            ''
                          )}
                        </ul>
                      </div>
                      <div className="entry-content" dangerouslySetInnerHTML={{ __html: noticia.texto }} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
