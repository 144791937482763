import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTitle } from '../../../hooks/useTitle';
import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics';

export const Legales = () => {
  useTitle('Legales');
  useGoogleAnalytics();
  const { t } = useTranslation('legales');
  return (
    <section id="content">
      <div className="content-wrap py-5">
        <div className="container clearfix">
          <h1>Legales</h1>
          <ul>
            <li>
              <h4>
                <Link to="/legales/aviso-privacidad" className="text-black underlined">
                  {t('avisoPrivacidad')}
                </Link>
              </h4>
            </li>
            <li>
              <h4>
                <Link to="/legales/terminos-y-condiciones" className="text-black underlined">
                  {t('terminosCondiciones')}
                </Link>
              </h4>
            </li>
            <li>
              <h4>
                <Link to="/legales/politica-reembolsos-cancelaciones" className="text-black underlined">
                  Política de reembolsos y cancelaciones
                </Link>
              </h4>
            </li>
            <li>
              <h4>
                <Link to="/legales/">Aviso de privacidad para correos electrónicos</Link>
              </h4>
            </li>
            <li>
              <h4>
                <Link to="/legales/">Aviso de privacidad para llamadas telefónicas</Link>
              </h4>
            </li>
            <li>
              <h4>
                <Link to="/legales/">
                  Aviso de privacidad para añadir a las listas de registro y asistencia de participantes a nuestros
                  eventos
                </Link>
              </h4>
            </li>
            <li>
              <h4>
                <Link to="/legales/">
                  Aviso de privacidad para incluir en formatos de registro en nuestra página de internet para participar
                  en una actividad
                </Link>
              </h4>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
