import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Store } from 'react-notifications-component';
import { API_URL, GOOGLE_SITE_KEY } from '../../../settings/settings';
import validator from 'validator/es';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';

export const FormularioContacto = () => {
  const { t, i18n } = useTranslation('contacto');
  const { register, handleSubmit, setValue, trigger, errors } = useForm();
  const recaptchaRef = useRef();
  const submitBtnRef = useRef();
  const onSubmit = (data, e) => {
    submitBtnRef.current.disabled = true;
    setTimeout(function () {
      axios
        .post(`${API_URL}/v1/formulario-contactos/`, data)
        .then((response) => {
          if (response.status === 200) {
            Store.addNotification({
              title: t('formulario.contacto'),
              message: t('formulario.correcto'),
              type: 'success',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 8000,
                pauseOnHover: true,
              },
            });
            e.target.reset();
            e.target.elements.captcha.value = '';
          }
        })
        .catch((error) => {
          if (error.response?.status === 400) {
            const message =
              i18n.language === 'en'
                ? error.response.data.detail.en.toString()
                : error.response.data.detail.es.toString();
            Store.addNotification({
              title: t('formulario.contacto'),
              message,
              type: 'warning',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 8000,
                pauseOnHover: true,
              },
            });
          } else {
            Store.addNotification({
              title: t('formulario.contacto'),
              message: t('formulario.error'),
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 8000,
                pauseOnHover: true,
              },
            });
          }
        })
        .finally(() => {
          recaptchaRef.current.reset();
          submitBtnRef.current.disabled = false;
        });
    }, 1000);
  };

  return (
    <form className="mb-0" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-md-12 form-group">
          <label htmlFor="nombre">
            {t('nombre')} <small>*</small>
          </label>
          <input name="nombre" id="nombre" ref={register({ required: true })} className="sm-form-control required" />
          {errors.nombre && <div className="invalid-input">{t('error.requerido')}</div>}
        </div>
        <div className="w-100" />
        <div className="col-md-12 form-group">
          <label htmlFor="email">
            {t('correo')} <small>*</small>
          </label>
          <input
            name="email"
            id="email"
            type="email"
            ref={register({
              required: true,
              validate: {
                isValidEmail: (value) => validator.isEmail(value),
              },
            })}
            className="sm-form-control required email"
          />
          {errors.email && errors.email.type === 'required' && (
            <div className="invalid-input">{t('error.requerido')}</div>
          )}
          {errors.email && errors.email.type === 'isValidEmail' && (
            <div className="invalid-input">{t('error.emailInvalido')}</div>
          )}
        </div>
        <div className="w-100" />
        <div className="col-md-12 form-group">
          <label htmlFor="telefono">{t('telefono')}</label>
          <input
            name="telefono"
            id="telefono"
            ref={register({ required: false })}
            className="sm-form-control required"
          />
        </div>

        <div className="w-100" />

        <div className="col-12 form-group">
          <label htmlFor="mensaje">
            {t('mensaje')} <small>*</small>
          </label>
          <textarea
            name="mensaje"
            id="mensaje"
            className="required sm-form-control"
            rows="7"
            cols="30"
            ref={register({ required: true })}
          />
          {errors.mensaje && <div className="invalid-input">{t('error.requerido')}</div>}
        </div>
        <input type="hidden" name="captcha" ref={register({ required: true })} />
        <div className="col-12 form-group">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={GOOGLE_SITE_KEY}
            onChange={(value) => {
              setValue('captcha', value);
              trigger('captcha');
            }}
            hl={i18n.language}
          />
          {errors.captcha && errors.captcha.type === 'required' && (
            <div className="invalid-input">captcha {t('error.requerido')}</div>
          )}
        </div>
        <div className="col-12 form-group">
          <button className="btn btn-secondary" type="submit" value="submit" ref={submitBtnRef}>
            {t('enviarMensaje')}
          </button>
        </div>
      </div>
    </form>
  );
};
