import {
  // useSpring,
  animated,
} from 'react-spring';
import { motion } from 'framer-motion';

// const calc = (x, y) => [-(y - window.innerHeight / 2) / 100, (x - window.innerWidth / 2) / 100, 1.1];
// const trans = (x, y, s) => `perspective(900px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

export const Tarjeta = ({ nombre, puesto, imagenUrl }) => {
  // const [props, set] = useSpring(() => {
  //     return ({ xys: [ 0, 0, 1 ], config: { mass: 5, tension: 350, friction: 35 } });
  // });
  // const style = {
  //     boxShadow: '0 10px 30px -5px rgba(0, 0, 0, 0.3)',
  //     transition: 'box-shadow 0.5s',
  //     willChange: 'transform',
  //     '&:hover': {
  //         boxShadow: '0 30px 100px -10px rgba(0, 0, 0, 0.4)'
  //     }
  // }
  return (
    <motion.div whileTap={{ scale: 0.97 }}>
      <animated.div
        // onMouseMove={ ( { clientX: x, clientY: y } ) => set( { xys: calc( x, y ) } ) }
        // onMouseLeave={ () => set( { xys: [ 0, 0, 1 ] } ) }
        // style={ { ...style, transform: props.xys.interpolate( trans ) } }
        className="aligncenter equipo-ceead"
      >
        <div className="card h-100 rounded shadow">
          <img src={imagenUrl} className="card-img-top mt-3" alt={nombre} />
          <div className="card-body text-center">
            <h4 className="card-title">{nombre}</h4>
            <p className="card-text">{puesto}</p>
          </div>
        </div>
      </animated.div>
    </motion.div>
  );
};
