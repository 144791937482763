import { useEffect, useState } from 'react';

const ScrollUpButton = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
  });

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  return (
    <a
      id="scrollUp"
      onClick={scrollTop}
      className={`scrollUp_btn ${showScroll ? 'scrollUp_btn--show' : 'scrollUp_btn--hidde'}`}
    >
      <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7 1V17M7 1L13 7M7 1L1 7"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </a>
  );
};

export default ScrollUpButton;
