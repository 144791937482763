import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { uiShowModal, uiHideModal } from '../../../../actions/ui';
import { AnimatePresence } from 'framer-motion';
import { Tarjeta } from './Tarjeta';
import { ModalPerfil } from './modal/ModalPerfil';
import equipo from './../../../../data/equipo2023.json';
import { useTitle } from '../../../../hooks/useTitle';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';

export const EquipoCeead = () => {
  useTitle('Equipo CEEAD');
  useGoogleAnalytics();
  const { t, i18n } = useTranslation('quienesSomos');
  const dispatch = useDispatch();
  const { modalContent } = useSelector((state) => state.modal);
  const toggle = () => {
    dispatch(uiHideModal());
  };
  return (
    <section id="content">
      <div className="content-wrap py-0">
        <div className="container py-5 clearfix">
          <div className="row">
            <div className="col-12">
              <h2 className="dots text-red">{t('equipo.title')}</h2>
            </div>
          </div>
          {equipo.map((area, index) => (
            <div className="row" key={index}>
              <div className="col-12">
                <h3>{i18n.language === 'en' ? area?.nombre.en : area?.nombre.es}</h3>
              </div>
              {area?.equipo.map((persona, index) => (
                <div
                  key={index}
                  className="col-sm-12 col-md-6 col-lg-3 mb-5"
                  onClick={() =>
                    dispatch(
                      uiShowModal({
                        content: `
                        <h2 class="text-white m-0">${persona.nombre}</h2>
                        <h3 class="text-white mb-0 font-weight-light">${
                          i18n.language === 'en' ? persona.puesto.en : persona.puesto.es
                        }</h3>
                        <hr class="mt-3 mb-4" style='border-color: #616161;' />
                        <div>${i18n.language === 'en' ? persona.desc.en : persona.desc.es}</div>
                        `,
                        person: persona.nombre,
                      }),
                    )
                  }
                >
                  <Tarjeta
                    nombre={persona.nombre}
                    puesto={i18n.language === 'en' ? persona.puesto.en : persona.puesto.es}
                    imagenUrl={persona.imagen} // mientras
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <AnimatePresence>
        {modalContent && <ModalPerfil closeModal={toggle} modalContent={modalContent} />}
      </AnimatePresence>
    </section>
  );
};
