import { useTitle } from '../../../../hooks/useTitle';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';

export const PoliticaReembolsosCancelaciones = () => {
  useTitle('Política de reembolsos y cancelaciones');
  useGoogleAnalytics();
  return (
    <section id="content">
      <div className="content-wrap py-5">
        <div className="container clearfix">
          <h2>Política de reembolsos y cancelaciones</h2>

          <h3>I. GENERALES</h3>

          <p>
            La cancelación de inscripciones a cualquier evento debe ser realizada mediante email dirigido a{' '}
            <a href="mailto:info@ceead.org.mx"> info@ceead.org.mx</a> indicando el nombre completo de la persona
            inscrita, cuenta de correo y número telefónico, motivo de la baja o cancelación, así como la fecha y nombre
            y sede del evento para el cual se encontraba inscrito(a). Deberá incluir también los datos de la misma
            cuenta bancaria o el medio desde el cual se realizó el pago:
          </p>

          <ul className="pl-5">
            <li> Carátula de cuenta bancaria</li>
            <li> Usuario Paypal (en su caso)</li>
            <li> Copia por ambos lados de una identificación oficial</li>
            <li> Comprobante de pago (en caso de transferencia)</li>
          </ul>

          <h3>II. TASAS DE REEMBOLSO</h3>

          <ul className="pl-5">
            <li>
              Cancelaciones 15 o más días naturales previos al inicio del evento o ciclo escolar se reembolsará el 100%
              del importe pagado, excepto los gastos de gestión que pudieran ocasionarse.
            </li>
            <li>
              Cancelaciones entre 14 y 3 días naturales previos al inicio del evento o ciclo escolar se reembolsará el
              50% del importe pagado, excepto los gastos de gestión que pudieran ocasionarse.
            </li>
            <li>Cancelaciones con menos de 3 días naturales de anticipación no dará derecho a devolución alguna.</li>
            <li>En caso de no presentarse al evento, no habrá devolución alguna.</li>
            <li>
              Cancelación o bajas de eventos o ciclos académicos pagados y facturados en el año fiscal anterior, se
              reembolsará el 50% del importe pagado (siempre que se cumpla con los puntos anteriores), menos los gastos
              de gestión que pudieran ocasionarse.
            </li>
            <li>
              En caso de que el evento sea cancelado por causas imputables al CEEAD , se devolverá al participante, el
              100% de los pagos realizados.
            </li>
            <li>
              Cancelación de evento por causas o razones de “Fuerza Mayor”, se devolverá al participante, el 100% de los
              pagos realizados.
            </li>
            <li>
              Para los convenios de pagos en parcialidades se podrá cancelar la deuda según los tiempos de baja antes
              mencionados, de lo contrario el participante deberá cubrir los pagos que le apliquen de acuerdo a la fecha
              de baja.
            </li>
          </ul>

          <p>
            En ningún momento se podrá exigir al CEEAD cualquier otro tipo de compensación mayor al importe del evento
            pagado. Los reembolsos se realizarán dentro de los 5 días hábiles posteriores al aviso de cancelación y al
            envío de los datos bancarios o del medio desde el cual se realizó el pago siendo éstos los únicos medios de
            reembolso.
          </p>
          <p>
            El abandono sin explicación o la baja del evento o ciclo académico que no está excusada bajo una razón de
            fuerza mayor, no exime al estudiante de pagar las cuotas o dineros adeudados del curso o del ciclo académico
            al que se haya inscrito.
          </p>

          <h3>III. CANCELACIÓN DE EVENTOS POR CAUSAS O RAZONES DE “FUERZA MAYOR”</h3>

          <p>
            “Fuerza Mayor” se entiende como cualquier evento más allá del control del CEEAD (Centro de Estudios sobre la
            Enseñanza y el Aprendizaje del Derecho, A.C.), de naturaleza impredecible e inevitable que impida que el
            CEEAD pueda brindar un servicio pactado. Se consideran casos de “Fuerza Mayor” o fortuitos aquellos que
            habitualmente se reconocerían como tales por la jurisprudencia de los tribunales, y los detallados más
            abajo.
          </p>
          <p>
            El CEEAD (Centro de Estudios sobre la Enseñanza y el Aprendizaje del Derecho, A.C.) reembolsará el 100% del
            importe pagado
          </p>
          <p>Causas o razones de “Fuerza Mayor”:</p>

          <ol type="1">
            <li>
              Muerte inesperada del instructor/facilitador/docente o de un miembro de su familia directa
              (cónyuge/pareja, hijos, padres, tutores legales, abuelos o hermanos).
            </li>
            <li>Lesiones graves que le impidan dictar el evento al instructor/facilitador/ docente.</li>
            <li>
              Catástrofes naturales de importancia o condiciones meteorológicas adversas de gran intensidad que afecten
              al lugar de destino o de salida del instructor/facilitador/ docente.
            </li>
            <li>
              Restricciones de viaje urgentes o alertas de seguridad graves publicadas por las autoridades nacionales o
              internacionales competentes.
            </li>
            <li>
              Enfermedad endémica, epidemia o pandemia declarada por una autoridad nacional o internacional fiable (como
              el Centro Nacional de Programas Preventivos y Control de Enfermedades o la Organización Mundial de la
              Salud).
            </li>
            <li>
              Daños graves en el lugar a impartir el servicio que afecten directamente a la capacidad del CEEAD para
              garantizar la seguridad durante el evento.
            </li>
            <li>
              Las causas o motivos de la baja o cancelación de la inscripción del inscrito, serán evaluados por un
              Comité que decidirá en cada caso si los hechos acaecidos son o no fuerza mayor y en caso de que lo sean,
              excusará al estudiante de pagar las cuotas adeudadas del ciclo académico o evento en el que estuviera
              inscrito.
            </li>
            <li>
              Para cualquier causa no descrita en este documento, el Comité se reserva el derecho de analizarlo y dar
              fallo, el cual será inapelable
            </li>
          </ol>

          <p>
            Esta política está sujeta a cambios en cualquier momento, sin previo aviso, escrito o de cualquier otra
            forma con el fin de actualizar y reflejar los cambios en nuestra operación, siendo responsabilidad del
            interesado mantenerse informado. Nos comprometemos a mantener éste documento actualizado sobre los cambios
            que pueda sufrir.
          </p>

          <br />

          <small>
            CÓDIGO: <b>CEEAD-P-CFI-022</b> <br />
            VERSIÓN: <b>02</b> <br />
            EMISIÓN: <b>17/09/2022</b>
          </small>
        </div>
      </div>
    </section>
  );
};
