import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../../hooks/useTitle';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';

export const TerminosCondiciones = () => {
  const { t } = useTranslation('terminosCondiciones');
  useTitle('Términos y condiciones');
  useGoogleAnalytics();
  return (
    <section id="content">
      <div className="content-wrap py-5">
        <div className="container clearfix">
          <h2>{t('titulo')}</h2>

          <h3>{t('seccion1.titulo')}</h3>

          <p dangerouslySetInnerHTML={{ __html: t('seccion1.p1') }} />

          <p dangerouslySetInnerHTML={{ __html: t('seccion1.p2') }} />

          <p dangerouslySetInnerHTML={{ __html: t('seccion1.p3') }} />

          <p dangerouslySetInnerHTML={{ __html: t('seccion1.p4') }} />

          <h3>{t('seccion2.titulo')}</h3>

          <p dangerouslySetInnerHTML={{ __html: t('seccion2.p1') }} />

          <p dangerouslySetInnerHTML={{ __html: t('seccion2.p2') }} />

          <p dangerouslySetInnerHTML={{ __html: t('seccion2.p3') }} />

          <p dangerouslySetInnerHTML={{ __html: t('seccion2.p4') }} />

          <p dangerouslySetInnerHTML={{ __html: t('seccion2.p5') }} />

          <p dangerouslySetInnerHTML={{ __html: t('seccion2.p6') }} />

          <h3>{t('seccion3.titulo')}</h3>

          <p dangerouslySetInnerHTML={{ __html: t('seccion3.p1') }} />

          <ul>
            <li dangerouslySetInnerHTML={{ __html: t('seccion3.l1') }} />
            <li dangerouslySetInnerHTML={{ __html: t('seccion3.l2') }} />
            <li dangerouslySetInnerHTML={{ __html: t('seccion3.l3') }} />
            <li dangerouslySetInnerHTML={{ __html: t('seccion3.l4') }} />
            <li dangerouslySetInnerHTML={{ __html: t('seccion3.l5') }} />
            <li dangerouslySetInnerHTML={{ __html: t('seccion3.l6') }} />
            <li dangerouslySetInnerHTML={{ __html: t('seccion3.l7') }} />
            <li dangerouslySetInnerHTML={{ __html: t('seccion3.l8') }} />
          </ul>

          <h3>{t('seccion4.titulo')}</h3>

          <p dangerouslySetInnerHTML={{ __html: t('seccion4.p1') }} />

          <p dangerouslySetInnerHTML={{ __html: t('seccion4.p2') }} />

          <p dangerouslySetInnerHTML={{ __html: t('seccion4.p3') }} />

          <p dangerouslySetInnerHTML={{ __html: t('seccion4.p4') }} />

          <p dangerouslySetInnerHTML={{ __html: t('seccion4.p5') }} />

          <h3>{t('seccion5.titulo')}</h3>

          <p dangerouslySetInnerHTML={{ __html: t('seccion5.p1') }} />

          <h3>{t('seccion6.titulo')}</h3>

          <p dangerouslySetInnerHTML={{ __html: t('seccion6.p1') }} />

          <h3>{t('seccion7.titulo')}</h3>

          <p dangerouslySetInnerHTML={{ __html: t('seccion7.p1') }} />

          <ol type="1">
            <li dangerouslySetInnerHTML={{ __html: t('seccion7.l1') }} />
            <li dangerouslySetInnerHTML={{ __html: t('seccion7.l2') }} />
            <li dangerouslySetInnerHTML={{ __html: t('seccion7.l3') }} />
            <li dangerouslySetInnerHTML={{ __html: t('seccion7.l4') }} />
            <li dangerouslySetInnerHTML={{ __html: t('seccion7.l5') }} />
          </ol>

          <p dangerouslySetInnerHTML={{ __html: t('seccion7.p2') }} />
        </div>
      </div>
    </section>
  );
};
