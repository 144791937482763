import { useTranslation } from 'react-i18next';
import { SectionImage } from '../../../ui/sections/SectionImage';

import banner from './images/banner.jpg';
import logoCejume from './images/logo_cejume.png';
import bannerCursos from './images/cursos_personal_operador.jpg';
import { Link } from 'react-router-dom';
import { useTitle } from '../../../../hooks/useTitle';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';
import { TituloCategoria } from '../capacitacion_formacion/TituloCategoria';
import personalOperador from '../capacitacion_formacion/images/personal_operador.jpg';
import { Tarjeta3d } from '../capacitacion_formacion/Tarjeta3d';
import exmida from '../capacitacion_formacion/images/cejume/exmida.jpg';
import champ from '../capacitacion_formacion/images/cejume/champ.jpg';
import exmasc from '../capacitacion_formacion/images/cejume/exmasc.jpg';
import chapc from '../capacitacion_formacion/images/cejume/chapc.jpg';
import cursosComplementarios from '../capacitacion_formacion/images/cursos_complementarios.jpg';
import genero from '../capacitacion_formacion/images/cejume/genero_cejume.jpg';
import etica from '../capacitacion_formacion/images/cejume/etica_cejume.jpg';
import perfil1 from '../../../../assets/pdf/perfiles/220316_AJV-Perfil_Competencias_CEJUME-v2.pdf';
import perfil2 from '../../../../assets/pdf/perfiles/220316_DEF-Perfil_Competencias_CEJUME 2 2.pdf';
import perfil3 from '../../../../assets/pdf/perfiles/220316_CAP-Perfil_Competencias_CEJUME 2.pdf';
import perfil4 from '../../../../assets/pdf/perfiles/220803_FAC-Perfil_Competencias_CEJUME.pdf';
import perfil5 from '../../../../assets/pdf/perfiles/220316_AMP-Perfil_Competencias_CEJUME.pdf';

export const EvaluacionCertificacion = () => {
  useTitle('Evaluación y certificación profesional');
  useGoogleAnalytics();
  const { t } = useTranslation('evaluacionCertificacion');
  const { t: t2 } = useTranslation('comoTransformamos');
  return (
    <section id="content">
      <div className="content-wrap py-0">
        <SectionImage backgroundImage={banner} padding="90px 0">
          <div className="emphasis-title text-left">
            <h2 className="m-0 font-weight-bold">{t('titulo')}</h2>
          </div>
        </SectionImage>

        <div className="container clearfix">
          <div className="row pb-5">
            <div className="col">
              <h2 className="dots">{t('evaluacionesProfesionales.titulo')}</h2>
              <h3 className="mb-1">{t('evaluacionesProfesionales.tituloParrafo1')}</h3>
              <p>{t('evaluacionesProfesionales.parrafo1')}</p>
              <h3 className="mb-1">{t('evaluacionesProfesionales.tituloParrafo2')}</h3>
              <p>{t('evaluacionesProfesionales.parrafo2')}</p>
              <h3 className="mb-1">{t('evaluacionesProfesionales.tituloParrafo3')}</h3>
              <p>{t('evaluacionesProfesionales.parrafo3')}</p>
              <ul className="ml-5">
                <li>
                  <a href={perfil1} target="_blank" rel="noreferrer">
                    {t('evaluacionesProfesionales.perfil1')}
                  </a>
                </li>
                <li>
                  <a href={perfil2} target="_blank" rel="noreferrer">
                    {t('evaluacionesProfesionales.perfil2')}
                  </a>
                </li>
                <li>
                  <a href={perfil3} target="_blank" rel="noreferrer">
                    {t('evaluacionesProfesionales.perfil3')}
                  </a>
                </li>
                <li>
                  <a href={perfil4} target="_blank" rel="noreferrer">
                    {t('evaluacionesProfesionales.perfil4')}
                  </a>
                </li>
                <li>
                  <a href={perfil5} target="_blank" rel="noreferrer">
                    {t('evaluacionesProfesionales.perfil5')}
                  </a>
                </li>
              </ul>
              <iframe
                width="1280"
                height="720"
                src="https://www.youtube.com/embed/leG0-iM5WB0"
                title="Perfiles basados en estándares de competencia de personal que opera el SJP en México"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <br />
              <br />
              <h3 className="mb-1">{t('evaluacionesProfesionales.tituloParrafo4')}</h3>
              <p>{t('evaluacionesProfesionales.parrafo4')}</p>
              <h3 className="mb-1">{t('evaluacionesProfesionales.tituloParrafo5')}</h3>
              <p>{t('evaluacionesProfesionales.parrafo5')}</p>
            </div>
          </div>
          <div className="row pb-5">
            <div className="col">
              <h2 className="dots">{t('certificacionesProfesionales.titulo')}</h2>
              <p>{t('certificacionesProfesionales.parrafo1')}</p>
              <p>{t('certificacionesProfesionales.parrafo2')}</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h2 className="dots">CEJUME</h2>
              <div className="text-center">
                <a href="https://cejume.mx" target="_blank" rel="noreferrer">
                  <img className="pb-5" src={logoCejume} alt="CEJUME" />
                </a>
              </div>
              <p>{t('cejume.parrafo1')}</p>
              <p>{t('cejume.parrafo2')}</p>
              <p>{t('cejume.parrafo3')}</p>
            </div>
          </div>
        </div>
        <div className="container py-5 clearfix center">
          <h4>{t('cejume.masInfo')}</h4>
          <a href="https://cejume.mx" target="_blank" rel="noreferrer" className="btn btn-lg btn-secondary">
            www.cejume.mx <i className="icon-arrow-alt-circle-right1" />
          </a>
        </div>

        <TituloCategoria
          backgroundImage={personalOperador}
          title={t2('capacitacionFormacion.certificacionJusticia')}
          id="personal-operador"
        />
        <div className="container my-0 px-5 py-5 pt-5 pb-5 clearfix">
          <div className="row row-cols-1 row-cols-md-3 row-cols-xl-3">
            <div className="col mb-2 p-3">
              <Tarjeta3d titulo={t2('capacitacionFormacion.exmida')} image={exmida} />
            </div>

            <div className="col mb-2 p-3">
              <Tarjeta3d titulo={t2('capacitacionFormacion.champ')} image={champ} />
            </div>

            <div className="col mb-2 p-3">
              <Tarjeta3d titulo={t2('capacitacionFormacion.exmasc')} image={exmasc} />
            </div>
            <div className="col mb-2 p-3">
              <Tarjeta3d titulo={t2('capacitacionFormacion.hpc')} image={chapc} />
            </div>
            {/* <div className="col mb-2 p-3"> */}
            {/*    <Tarjeta3d titulo={ t2( 'capacitacionFormacion.hdp' ) } image={ placeholder }/> */}
            {/* </div> */}
            {/* <div className="col mb-2 p-3"> */}
            {/*    <Tarjeta3d titulo={ t2( 'capacitacionFormacion.hajv' ) } image={ placeholder }/> */}
            {/* </div> */}
            {/* <div className="col mb-2 p-3"> */}
            {/*    <Tarjeta3d titulo={ t2( 'capacitacionFormacion.hfmasc' ) } image={ placeholder }/> */}
            {/* </div> */}
          </div>
        </div>

        <TituloCategoria
          backgroundImage={cursosComplementarios}
          title={t2('capacitacionFormacion.complementariosCertificacionJusticia')}
        />
        <div className="container my-0 px-5 py-5 pt-5 pb-5 clearfix">
          <div className="row row-cols-1 row-cols-md-3 row-cols-xl-3">
            <div className="col mb-2 p-3">
              <Tarjeta3d titulo={t2('capacitacionFormacion.genero')} image={genero} />
            </div>
            <div className="col mb-2 p-3">
              <Tarjeta3d titulo={t2('capacitacionFormacion.etica')} image={etica} />
            </div>
          </div>
        </div>

        <SectionImage backgroundImage={bannerCursos} padding="60px 0">
          <div className="emphasis-title text-left">
            <Link to="/como-transformamos/capacitacion-y-formacion">
              <h3 className="m-0">
                <i className="icon-arrow-alt-circle-right1" /> {t('enlaceCursos')}
              </h3>
            </Link>
          </div>
        </SectionImage>
      </div>
    </section>
  );
};
