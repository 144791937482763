import { useTranslation } from 'react-i18next';
import { SectionImage } from '../../../ui/sections/SectionImage';
import banner from './images/banner.jpg';
import observatorio from '../../../../assets/images/logos/logo_Observatorio.svg';
import exsipa from '../../../../assets/images/logos/logo_EXIPA.svg';
import bannerFolleto from './images/banner_folleto.jpg';
import brochureEs from '../../../../assets/pdf/191120_Brochure_EXSIPA_2020.pdf';
import brochureEn from '../../../../assets/pdf/191120_Brochure_EXSIPA_2020-eng.pdf';
import { useTitle } from '../../../../hooks/useTitle';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';

export const HerramientasDiagnostico = () => {
  useTitle('Herramientas de diagnóstico y evaluación para conocer el estado de la educación jurídica');
  useGoogleAnalytics();
  const { t, i18n } = useTranslation('comoTransformamos');
  return (
    <section id="content">
      <div className="content-wrap py-0">
        <SectionImage backgroundImage={banner}>
          <div className="emphasis-title text-left">
            <h2 className="font-weight-bold">{t('herramientasDiagnostico.titulo')}</h2>
            <h4>{t('herramientasDiagnostico.descripcion')}</h4>
          </div>
        </SectionImage>

        <div className="container clearfix my-5">
          <div className="row">
            <div className="col-12">
              <div className="container clearfix my-4 center">
                <img src={observatorio} alt="observatorio" />
              </div>
              <p className="text-center">{t('herramientasDiagnostico.observatorioParrafo1')}</p>
              <p>{t('herramientasDiagnostico.tituloLista')}</p>
              <ul>
                <li>{t('herramientasDiagnostico.item1')}</li>
                <li>{t('herramientasDiagnostico.item2')}</li>
                <li>{t('herramientasDiagnostico.item3')}</li>
                <li>{t('herramientasDiagnostico.item4')}</li>
                <li>{t('herramientasDiagnostico.item5')}</li>
                <li>{t('herramientasDiagnostico.item6')}</li>
              </ul>
              <p>{t('herramientasDiagnostico.observatorioParrafo2')}</p>
              <h4 className="text-center font-weight-bold">{t('herramientasDiagnostico.masInfo')}</h4>
              <p className="text-center">
                <a
                  href="https://observatorioceead.mx"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-lg btn-secondary"
                >
                  www.observatorioceead.mx <i className="icon-arrow-alt-circle-right1" />
                </a>
              </p>
              <div className="my-4" />
              <div className="container clearfix my-5 center">
                <img src={exsipa} alt="exsipa" className="mx-auto" />
              </div>
              <p>{t('herramientasDiagnostico.exsipaParrafo1')}</p>
              <p>{t('herramientasDiagnostico.exsipaParrafo2')}</p>
              <p>{t('herramientasDiagnostico.exsipaParrafo3')}</p>
            </div>
          </div>
        </div>

        <SectionImage backgroundImage={bannerFolleto} padding="50px 0">
          <div className="emphasis-title">
            <h3>{t('herramientasDiagnostico.tituloVentajas')}</h3>
            <p>
              <a
                href={i18n.language === 'en' ? brochureEn : brochureEs}
                target="_blank"
                rel="noreferrer"
                className="btn btn-lg btn-warning"
              >
                {t('herramientasDiagnostico.botonVentajas')} <i className="icon-arrow-alt-circle-right1" />
              </a>
            </p>
          </div>
        </SectionImage>

        <div className="container mt-5 mb-3 clearfix">
          <h4 className="text-center font-weight-bold">{t('herramientasDiagnostico.materialConsulta')}</h4>
          <p className="text-center">
            <a
              href="https://www.ceneval.edu.mx/documents/20182/48295/Guia+EXSIPA+2019.pdf"
              target="_blank"
              rel="noreferrer"
              className="btn btn-lg btn-secondary"
            >
              {t('herramientasDiagnostico.botonConsulta')} <i className="icon-arrow-alt-circle-right1" />
            </a>
          </p>

          <h4
            className="text-center font-weight-bold my-5"
            dangerouslySetInnerHTML={{ __html: t('herramientasDiagnostico.textoInteres') }}
          />
        </div>
      </div>
    </section>
  );
};
