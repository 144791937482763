import { Carousel } from 'react-bootstrap';
import events from '../../../data/eventos.json';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CarouselImage from './CarouselImage';

export const WidgetCalendario = () => {
  const { t, i18n } = useTranslation('proximosEventos');
  const handleClick = (eventoId) => {
    console.log(`clicked: ${eventoId}`);
  };

  return (
    <div className="widget clearfix">
      <h3>{t('titulo')}</h3>
      <Carousel indicators={false}>
        {events
          .filter((evento) => evento.type === 'evento')
          .map((evento) => (
            <Carousel.Item key={evento.id} onClick={() => handleClick(evento.id)} style={{ cursor: 'pointer' }}>
              <CarouselImage
                src={evento.extendedProps.imageUrl}
                alt={i18n.language === 'en' ? evento.title.en : evento.title.es}
              />
            </Carousel.Item>
          ))}
      </Carousel>
      <p className="text-center mt-3">
        <Link to="/calendario-actividades" className="btn btn-secondary">
          {t('verCalendario')}
        </Link>
      </p>
    </div>
  );
};
