import ReactSlick from 'react-slick';
// import { Link } from 'react-router-dom';

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className="slick_arrow_next"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className="slick_arrow_prev"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const Slider = ({ slidesArray }) => {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <ReactSlick {...settings}>
      {slidesArray.map((slide, index) => (
        <>
          <a href={slide.url} key={index} target="_blank" rel="noreferrer">
            <img key={slide.alt} src={slide.cover} alt={slide?.alt} />
          </a>
        </>
      ))}
    </ReactSlick>
  );
};

export default Slider;
