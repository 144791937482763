import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import banner from './images/redes_banner.jpg';
import redesRepresentantes from './images/redes_representantes.jpg';
import programaSemilla from './images/semilla.webp';
import convocatoriaSemilla from '../../../../assets/pdf/convocatoria Semilla 2024-2025.pdf';
import { SectionImage } from '../../../ui/sections/SectionImage';
import { Formulario } from './Formulario';
import { useTitle } from '../../../../hooks/useTitle';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';

export const RedesColaboracion = () => {
  useTitle('Redes de colaboración');
  useGoogleAnalytics();
  const { t } = useTranslation('redesColaboracion');
  const [modalShow, setModalShow] = useState(false);
  return (
    <section id="content">
      <div className="content-wrap py-0">
        <SectionImage backgroundImage={banner} padding="90px 0">
          <div className="emphasis-title text-left">
            <h2 className="m-0 font-weight-bold">{t('titulo')}</h2>
          </div>
        </SectionImage>

        <div className="container pb-5 clearfix">
          <div className="row">
            <div className="col-12">
              <h2 className="dots">{t('redesTrabajo.titulo')}</h2>
              <p>{t('redesTrabajo.parrafo1')}</p>
              <ul>
                <li>{t('redesTrabajo.item1')}</li>
                <li>{t('redesTrabajo.item2')}</li>
                <li>{t('redesTrabajo.item3')}</li>
              </ul>
              <div className="text-center">
                <p>
                  <b>{t('redesTrabajo.parrafo2')}</b>
                </p>
                <p>
                  <button type="button" className="btn btn-lg btn-secondary" onClick={() => setModalShow(true)}>
                    {t('redesTrabajo.formulario')}
                  </button>
                </p>
                <Formulario show={modalShow} onHide={() => setModalShow(false)} />
              </div>
            </div>
          </div>
        </div>

        <div className="container pb-5 clearfix">
          <div className="row">
            <div className="col-12">
              <h2 className="dots">{t('representantes.titulo')}</h2>
              <p>{t('representantes.parrafo1')}</p>
              <p>{t('representantes.parrafo2')}</p>
            </div>
          </div>

          <div className="row mb-5 align-items-stretch">
            <div
              className="col-md-6 col-padding min-vh-75 order-md-last"
              style={{ background: `url('${redesRepresentantes}') center center no-repeat`, backgroundSize: 'cover' }}
            />
            <div className="col-md-6 col-padding">
              <ul>
                <li>{t('representantes.item1')}</li>
                <li>{t('representantes.item2')}</li>
                <li>{t('representantes.item3')}</li>
                <li>{t('representantes.item4')}</li>
                <li>{t('representantes.item5')}</li>
                <li>{t('representantes.item6')}</li>
                <li>{t('representantes.item7')}</li>
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <p>
                  <b>{t('representantes.parrafo3')}</b>
                </p>
                <p>
                  <a
                    href="https://cejume.mx"
                    target="_blank"
                    rel="noreferrer"
                    type="button"
                    className="btn btn-lg btn-secondary"
                  >
                    www.cejume.mx <i className="icon-arrow-alt-circle-right1" />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container clearfix" id="programaSemilla">
          <div className="row">
            <div className="col-12">
              <h2 className="dots">{t('programaSemilla.titulo')}</h2>
            </div>
          </div>
        </div>

        <img src={programaSemilla} className="w-100" alt="programa semilla" />

        <div className="container py-5 clearfix">
          <div className="row">
            <div className="col-12">
              <p>{t('programaSemilla.parrafo1')}</p>
              <p>{t('programaSemilla.parrafo2')}</p>
              <ol className="ml-5">
                <li>{t('programaSemilla.item1')}</li>
                <li>{t('programaSemilla.item2')}</li>
                <li>{t('programaSemilla.item3')}</li>
              </ol>
              <p>{t('programaSemilla.parrafo3')}</p>
              <div className="text-center">
                <p>
                  <b>{t('programaSemilla.participar')}</b>
                </p>
                <p>
                  <a href={convocatoriaSemilla} target="_blank" rel="noreferrer" className="btn btn-lg btn-secondary">
                    {t('programaSemilla.boton')} <i className="icon-arrow-alt-circle-right1" />
                  </a>
                </p>
              </div>
              {/* <div className="text-center"> */}
              {/*    <p><a href={ resultadosSemilla } target="_blank" rel="noreferrer" className="btn btn-lg btn-secondary">{ t('programaSemilla.botonResultados') } <i className="icon-arrow-alt-circle-right1" /></a></p> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
