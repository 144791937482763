import blog from '../../../data/blog.json';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../hooks/useTitle';
import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics';
import FontSizeChanger from 'react-font-size-changer';
import 'react-quill/dist/quill.bubble.css';

export const Post = ({ match }) => {
  const slug = match.params.slug;
  const post = blog.find((post) => post.slug === slug);
  useTitle('Blog CEEAD');
  useGoogleAnalytics();
  const { t, i18n } = useTranslation('blogCeead');
  return (
    <section id="content">
      <div className="content-wrap py-0">
        <div className="container py-5 clearfix">
          <div className="row">
            <div className="col-12">
              <h1 className="dots">{t('titulo')}</h1>
            </div>
            {i18n.language === 'en' && (
              <div className="col-12 pb-4">
                <h3>Only available in Spanish</h3>
              </div>
            )}
          </div>
          <div className="row">
            <div className="postcontent col-12">
              <div id="posts">
                {post ? (
                  <div className="entry" key={post.id}>
                    <div className="entry-image">
                      <img src={post.portada} alt={post.titulo} />
                    </div>
                    <div className="entry-meta mb-4">
                      <ul>
                        <li>
                          <i className="icon-calendar3" />
                          {post.fecha}
                        </li>
                        <li>
                          <i className="icon-user" />
                          {post.autor}
                        </li>
                      </ul>
                    </div>
                    <FontSizeChanger
                      targets={['.ql-editor']}
                      options={{
                        stepSize: 2,
                        range: 4,
                      }}
                      customButtons={{
                        up: <span style={{ fontSize: '30px', cursor: 'pointer' }}>A</span>,
                        down: <span style={{ fontSize: '15px', cursor: 'pointer' }}>A</span>,
                        style: {
                          WebkitBoxSizing: 'border-box',
                          WebkitBorderRadius: '5px',
                        },
                        buttonsMargin: 10,
                      }}
                    />
                    <div className="entry-content blog-post">
                      <div
                        dangerouslySetInnerHTML={{ __html: post.contenido }}
                        className="ql-editor"
                        style={{ color: '#000' }}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <h2 className="center">No se encontro la pagina</h2>
                  </div>
                )}
              </div>
              <div className="center">
                <Link to="/blog">
                  <button className="btn btn-lg btn-primary">{t('back')}</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
