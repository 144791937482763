import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MEDIA } from '../../../settings/settings';
// import Helpers from '../../../helpers/helpers';

export const Informe = ({ informe }) => {
  const { t, i18n } = useTranslation('quienesSomos');
  const isAuthenticated = true;
  const [
    downloadState,
    // setDownloadState
  ] = useState({
    errors: '',
    loading: false,
  });
  const url = i18n.language === 'en' ? MEDIA + informe.links.en : MEDIA + informe.links.es;
  // const handleDownloadFile = () => {
  //     setDownloadState({
  //         errors: '',
  //         loading: true
  //     });
  //     Helpers.httpRequest( url, 'get').then( response => {
  //         setDownloadState({
  //             errors: '',
  //             loading: false
  //         });
  //
  //     } ).catch( error => {
  //         console.log(error);
  //         setDownloadState({
  //             errors: 'Oops',
  //             loading: false
  //         });
  //     });
  // }
  const handleDownloadFile2 = () => {
    const a = document.createElement('a');
    a.href = url;
    a.download = '';
    a.target = '_blank';
    a.rel = 'noreferrer';
    a.click();
    a.remove();
  };
  return (
    <div className="card informe p-4 border-0">
      <img
        src={i18n.language === 'en' ? MEDIA + informe.thumbnails.en : MEDIA + informe.thumbnails.es}
        className="card-img-top rounded shadow"
        alt={i18n.language === 'en' ? MEDIA + informe.titles.en : MEDIA + informe.titles.es}
      />
      <div className="card-body">
        <h5 className="card-title mb-1">{i18n.language === 'en' ? informe.titles.en : informe.titles.es}</h5>
        <p>
          {isAuthenticated ? (
            <button
              disabled={downloadState.loading}
              className="btn btn-block btn-success"
              onClick={handleDownloadFile2}
            >
              {downloadState.loading ? t('informes.botonDescargando') : t('informes.botonDescarga')}
              {downloadState.loading ? (
                <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" />
              ) : (
                <i className="icon-download ml-2" />
              )}
            </button>
          ) : (
            // <a href={ url } className="btn btn-block btn-success" target="_blank" rel="noopener noreferrer" download>{ t('informes.botonDescarga') } <i className="icon-download ml-2" /></a>
            <span>{t('informes.botonRegistro')}</span>
          )}
          {/* { */}
          {/*    downloadState.errors && */}
          {/*        <div className="alert alert-danger">Error: { downloadState.errors || 'Algo salio mal.'}</div> */}
          {/* } */}
        </p>
      </div>
    </div>
  );
};
