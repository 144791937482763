import { useState } from 'react';
import { SectionImage } from '../../ui/sections/SectionImage';
import titleBackground from './images/preguntas_frecuentes.jpg';

import { useTranslation } from 'react-i18next';
import { Tabs, Accordion, Card, Tab } from 'react-bootstrap';
import { useTitle } from '../../../hooks/useTitle';
import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics';

export const PreguntasFrecuentes = () => {
  useTitle('Preguntas frecuentes');
  useGoogleAnalytics();
  const { t } = useTranslation('preguntasFrecuentes');
  const [key, setKey] = useState(t('ceead.titulo'));
  return (
    <section id="content">
      <div className="content-wrap py-0">
        <SectionImage backgroundImage={titleBackground}>
          <div className="emphasis-title text-left">
            <h2 className="dots text-black mb-0">{t('titulo')}</h2>
          </div>
        </SectionImage>

        <div className="container clearfix my-5">
          <div className="row">
            <div className="col-12">
              <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="faq-tabs">
                <Tab eventKey={t('ceead.titulo')} title={t('ceead.titulo')}>
                  <Accordion className="faq">
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="1"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('ceead.pregunta1') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <span className="text-black-50" dangerouslySetInnerHTML={{ __html: t('ceead.respuesta1') }} />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="2"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('ceead.pregunta2') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          <span className="text-black-50" dangerouslySetInnerHTML={{ __html: t('ceead.respuesta2') }} />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="3"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('ceead.pregunta3') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="3">
                        <Card.Body>
                          <span className="text-black-50" dangerouslySetInnerHTML={{ __html: t('ceead.respuesta3') }} />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="4"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('ceead.pregunta4') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="4">
                        <Card.Body>
                          <span className="text-black-50" dangerouslySetInnerHTML={{ __html: t('ceead.respuesta4') }} />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="5"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('ceead.pregunta5') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="5">
                        <Card.Body>
                          <span className="text-black-50" dangerouslySetInnerHTML={{ __html: t('ceead.respuesta5') }} />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="6"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('ceead.pregunta6') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="6">
                        <Card.Body>
                          <span className="text-black-50" dangerouslySetInnerHTML={{ __html: t('ceead.respuesta6') }} />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="7"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('ceead.pregunta7') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="7">
                        <Card.Body>
                          <span className="text-black-50" dangerouslySetInnerHTML={{ __html: t('ceead.respuesta7') }} />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Tab>
                <Tab eventKey={t('congreso.titulo')} title={t('congreso.titulo')}>
                  <Accordion className="faq">
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="1"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('congreso.pregunta1') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('congreso.respuesta1') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="2"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('congreso.pregunta2') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('congreso.respuesta2') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="3"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('congreso.pregunta3') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="3">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('congreso.respuesta3') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="4"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('congreso.pregunta4') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="4">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('congreso.respuesta4') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Tab>
                <Tab eventKey={t('expo.titulo')} title={t('expo.titulo')}>
                  <Accordion className="faq">
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="1"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('expo.pregunta1') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <span className="text-black-50" dangerouslySetInnerHTML={{ __html: t('expo.respuesta1') }} />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="2"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('expo.pregunta2') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          <span className="text-black-50" dangerouslySetInnerHTML={{ __html: t('expo.respuesta2') }} />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="3"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('expo.pregunta3') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="3">
                        <Card.Body>
                          <span className="text-black-50" dangerouslySetInnerHTML={{ __html: t('expo.respuesta3') }} />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Tab>
                <Tab eventKey={t('exmida.titulo')} title={t('exmida.titulo')}>
                  <Accordion className="faq">
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="1"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('exmida.pregunta1') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('exmida.respuesta1') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="2"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('exmida.pregunta2') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('exmida.respuesta2') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Tab>
                <Tab eventKey={t('semilla.titulo')} title={t('semilla.titulo')}>
                  <Accordion className="faq">
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="1"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta1') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta1') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="2"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta2') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta2') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="3"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta3') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="3">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta3') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="4"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta4') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="4">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta4') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="5"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta5') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="5">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta5') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="6"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta6') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="6">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta6') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="7"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta7') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="7">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta7') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="8"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta8') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="8">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta8') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="9"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta9') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="9">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta9') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="10"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta10') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="10">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta10') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="11"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta11') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="11">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta11') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="12"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta12') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="12">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta12') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="13"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta13') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="13">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta13') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="14"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta14') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="14">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta14') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="15"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta15') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="15">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta15') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="16"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta16') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="16">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta16') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="17"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta17') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="17">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta17') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="18"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta18') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="18">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta18') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="19"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta19') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="19">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta19') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="no-background">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="20"
                          className="no-underline no-background border-0 font-weight-bold"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="text-black-50">
                            <i className="icon-arrow-alt-circle-down1 icon-lg mr-2" />{' '}
                            <span dangerouslySetInnerHTML={{ __html: t('semilla.pregunta20') }} />
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="20">
                        <Card.Body>
                          <span
                            className="text-black-50"
                            dangerouslySetInnerHTML={{ __html: t('semilla.respuesta20') }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
