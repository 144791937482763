import { useSpring, animated } from 'react-spring';

const calc = (x, y) => [-(y - window.innerHeight / 2) / 100, (x - window.innerWidth / 2) / 100, 1.01];
const trans = (x, y, s) => `perspective(900px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

export const Tarjeta3d = ({ titulo, image }) => {
  const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 35 } }));
  const style = {
    boxShadow: '0 10px 30px -5px rgba(0, 0, 0, 0.3)',
    transition: 'box-shadow 0.5s',
    willChange: 'transform',
    '&:hover': {
      boxShadow: '0 30px 100px -10px rgba(0, 0, 0, 0.4)',
    },
  };
  return (
    <animated.div
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{ ...style, transform: props.xys.interpolate(trans) }}
    >
      <a href="https://aulaceead.mx/catalogo">
        <div className="card h-100 shadow rounded">
          <img src={image} className="card-img-top" alt="" />
          <div className="card-body">
            <h4 className="card-title text-center">{titulo}</h4>
          </div>
        </div>
      </a>
    </animated.div>
  );
};
